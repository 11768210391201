import { Button, Typography, styled } from "@mui/material";

export const DashboardLayout = styled("div")(({ theme }) => ({
  paddingLeft: "4%",
  paddingRight: "4%",
  paddingTop: "2%",
  backgroundColor: "#F6F7FB",
  height: "100vh",
}));

export const Title = styled(Typography)(() => ({
  fontWeight: "600",
  fontSize: "2rem",
  marginTop: ".5%",
  display: "flex",
  alignItems: "center",
}));

export const DetailsBtn = styled(Button)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: "500",
  color: "#FFFFFF",
  backgroundColor: "#127960",
  //border: "2px solid #679F38",
  borderRadius: "8px",
  textTransform: "none",
  width: "100%",
  //marginBottom: "3%",
  "&:hover": {
    backgroundColor: "#127960",
    color: "white",
  },
}));

export const Confirm = styled(Button)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: "500",
  color: "#FFFFFF",
  backgroundColor: "#127960",
  //border: "2px solid #679F38",
  borderRadius: "8px",
  textTransform: "none",
  width: "100%",
  //marginBottom: "3%",
  "&:hover": {
    backgroundColor: "#127960",
    color: "white",
  },
}));

export const Cancel = styled(Button)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: "500",
  color: "#000000",
  backgroundColor: "#FFFFFF",
  border: "1px solid #127960",
  borderRadius: "8px",
  textTransform: "none",
  width: "100%",
  //marginBottom: "3%",
  "&:hover": {
    backgroundColor: "#FFFFFF",
    color: "000000",
  },
}));
