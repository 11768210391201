export const isMainnet = process.env.REACT_APP_IS_MAINNET === "true";

export const rolesTestnet = {
  admin: "0xa49807205ce4d355092ef5a8a18f56e8913cf4a201fbe287825b095693c21775",
  validator: "0x21702c8af46127c7fa207f89d0b0a8441bb32959a0ac7df790e9ab1a25c98926",
};

export const rolesMainnet = {
  admin: "0xa49807205ce4d355092ef5a8a18f56e8913cf4a201fbe287825b095693c21775",
  validator: "0x21702c8af46127c7fa207f89d0b0a8441bb32959a0ac7df790e9ab1a25c98926",
};

const controllerMainnet = "0xBd2eeE7C96Ac0f21395c3C87912913169A84e201" as `0x${string}`;
const controllerTestnet = "0x2363E701b545FF3bE05d6FfE14466cBC7b8F8031" as `0x${string}`;

export const controllerContractAddress = isMainnet ? controllerMainnet : controllerTestnet;

const testnetExplorerUrl = "https://explorer.testnet.rootstock.io";
const mainnetExplorerUrl = "https://explorer.rootstock.io";

export const publicIpfsUrl = "https://ipfs.io/ipfs";
export const privateIpfsUrl = "https://ipfs.agrojusto.com.ar/ipfs";

export const explorerUrl = isMainnet ? mainnetExplorerUrl : testnetExplorerUrl;
export const ipfsUrl = isMainnet ? privateIpfsUrl : publicIpfsUrl;

export const testnetRpcUrl = "https://rpc.testnet.rootstock.io/fLlKQq4Y0dCP1fckCkUAKgxTFnoR4M-T";
export const mainnetRpcUrl = "https://rpc.mainnet.rootstock.io/H7KenTQ9twhQhmYSGwm2a5xevhiSEX-M";

export const magicApiKey = "pk_live_9FD7121A75E70C61";

export const redirectAfterLogoutUrl = "https://my.agrojusto.com.ar/";
