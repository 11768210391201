import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

export const Title = styled(Typography)(() => ({
  fontWeight: "600",
  fontSize: "2rem",
  marginTop: ".5%",
}));

export const GridContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gap: "16px",
  justifyContent: "center",
  gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",

  [theme.breakpoints.up("lg")]: {
    gridTemplateColumns: "repeat(4, 1fr)",
  },
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "1fr",
  },
}));
