
import { Toaster } from "react-hot-toast";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import OnboardUser from "./views/OnboardUser";
import { AuthProvider } from "./providers/auth";
import { ThemeProvider } from "@mui/material";
import theme from "./styles/theme";
import Dashboard from "./views/Dashboard";
import ViewProduct from "./views/ViewProduct";
import Validations from "./views/Validations";
import Transactions from "./views/Transactions";
import Process from "./views/Process";
import Profile from "./views/Profile";
import AdminPanel from "./views/AdminPanel";

const App = () => {

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <Toaster />
        <Router>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/validaciones" element={<Validations />} />
            <Route path="/transacciones" element={<Transactions />} />
            <Route path="/product/:productId" element={<ViewProduct />} />
            <Route path="/onboard/:userEmail" element={<OnboardUser />} />
            <Route path="/process/:id" element={<Process />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/admin" element={<AdminPanel />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;
