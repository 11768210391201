import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface IUser {
  isValidator: boolean;
  isAdmin: boolean;
}

interface DataStore {
  account: string | null;
  setAccount: (newAccount: string | null) => void;
  userToken: string | null;
  setUserToken: (newToken: string | null) => void;
  userData: any | null;
  setUserData: (newUserData: any[] | null) => void;
  userRole: IUser | null;
  setUserRole: (newUserRole: IUser | null) => void;
}

const useDataStore = create<DataStore>()(
  persist(
    set => ({
      account: null,
      setAccount: (newAccount: string | null) => set(state => ({ account: newAccount })),
      userToken: null,
      setUserToken: (newToken: string | null) => set(state => ({ userToken: newToken })),
      userData: null,
      setUserData: (newUserData: any[] | null) => set(state => ({ userData: newUserData })),
      userRole: null,
      setUserRole: (newUserRole: IUser | null) => set(state => ({ userRole: newUserRole })),
    }),
    { name: "my cool name" },
  ),
);

export default useDataStore;
