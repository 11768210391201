import { Magic } from "magic-sdk";
import { useContext, useState, useEffect, createContext } from "react";
import Web3 from "web3";
import useDataStore from "../../store";
import { useDatabase } from "../../hooks/useDatabase";
import { ControllerABI } from "../../utils/ControllerABI";
import { controllerContractAddress, magicApiKey, mainnetRpcUrl, redirectAfterLogoutUrl, rolesTestnet, testnetRpcUrl } from "../../utils/constants";
import { rolesMainnet } from "../../utils/constants";
import { isMainnet } from "../../utils/constants";

const rootstockTestnet = {
  rpcUrl: testnetRpcUrl,
  chainId: 31,
};

const rootstockMainnet = {
  rpcUrl: mainnetRpcUrl,
  chainId: 30,
};

const magic = new Magic(magicApiKey, {
  network: isMainnet ? rootstockMainnet : rootstockTestnet,
  locale: "es",
});

export const AuthContext = createContext<any>({
  web3: undefined,
  magic: undefined,
  handleConnect: async () => { },
  handleLogout: async () => { },
  account: undefined,
  isConnected: undefined,
  userToken: undefined,
  setUserToken: () => { },
  userPayload: undefined,
  setUserPayload: () => { },
});

export const { admin, validator } = isMainnet ? rolesMainnet : rolesTestnet;
const contractAddress = controllerContractAddress;
const parsedAddress = Web3.utils.toChecksumAddress(contractAddress);

export const AuthProvider = ({ children }: { children: any }) => {
  const [isConnected, setIsConnected] = useState(false);

  const [web3, setWeb3] = useState(new Web3());

  const [userToken, setUserToken] = useState<string | null>(null);
  const [userPayload, setUserPayload] = useState<any[] | null>(null);

  const { account, setAccount, setUserData, userRole, setUserRole } = useDataStore();
  const { handleRegisterUser } = useDatabase();

  useEffect(() => {
    if (typeof window !== "undefined") {
      const web3 = new Web3(magic.rpcProvider as any);
      setWeb3(web3);
    }
  }, []);

  const handleConnect = async ({ email, rawUserObject }: { email: string, rawUserObject: any }): Promise<boolean> => {
    try {
      // Log in the user with their email and send the OTP
      await magic.auth.loginWithEmailOTP({ email });

      // Fetch the user's wallet accounts (avoiding the UI popup)
      const accounts = await web3.eth.getAccounts();

      if (accounts.length > 0) {
        // Save in DB
        const userRegister = await handleRegisterUser({ account: accounts[0], ...rawUserObject });
        // Set user account and update state
        // User Role
        await getUserRole({ account: accounts[0] });
        setAccount(accounts[0]);
        setIsConnected(true);

        // Return true to indicate successful connection
        return true;
      } else {
        throw new Error("No accounts found after authentication");
      }
    } catch (error) {
      console.error("Error connecting:", error);
      return false;
    }
  };


  const getUserRole = async ({ account }: { account: string }) => {
    if (!web3) return;
    if (!account) return;

    try {
      const controllerContract = new web3.eth.Contract(ControllerABI as any, parsedAddress);

      const [isValidator, isAdmin] = await Promise.all([
        controllerContract.methods.hasRole(validator, account).call(),
        controllerContract.methods.hasRole(admin, account).call(),
      ]);
      setUserRole({ isValidator, isAdmin });
      return { isValidator, isAdmin };
    } catch (error) {
      console.error("Error getting user role:", error);
      return null;
    }
  };

  const handleLogout = async () => {
    await magic.user.logout();
    setAccount(null);
    setUserData(null);
    setUserRole(null);
    setUserToken(null);
    setUserPayload(null);
    setIsConnected(false);
    window.location.href = redirectAfterLogoutUrl;
  };

  return (
    <AuthContext.Provider
      value={{
        web3,
        handleConnect,
        account,
        isConnected,
        handleLogout,
        userToken,
        setUserToken,
        userPayload,
        setUserPayload,
        magic,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
