import * as React from "react";
import { useState, useEffect } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../../firebase";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as Styled from "./styles";

export default function EachProductCard({ product }: any) {
  const navigate = useNavigate();
  const [procesosCount, setProcesosCount] = useState(0);

  useEffect(() => {
    const fetchProcesosCount = async () => {
      if (!product?._id) return;

      try {
        const q = query(collection(db, "records"), where("product_id", "==", product._id));
        const querySnapshot = await getDocs(q);
        setProcesosCount(querySnapshot.size);
      } catch (error) {
        console.error("Error fetching procesos:", error);
      }
    };

    fetchProcesosCount();
  }, [product?._id]);

  return (
    <Card sx={{ minWidth: 200, maxWidth: 500, marginTop: "1em" }}>
      <CardContent>
        <Styled.ProductTitle>{product?.title}</Styled.ProductTitle>
        <Styled.ProductKey>
          ID: <Styled.ProductValue>{product?._id}</Styled.ProductValue>
        </Styled.ProductKey>
        <Styled.ProductKey>
          Categoría: <Styled.ProductValue>{product?.productType?.name}</Styled.ProductValue>
        </Styled.ProductKey>
        <Styled.ProductKey>
          Descripción:
          <Styled.ProductValue> {product?.description}</Styled.ProductValue>
        </Styled.ProductKey>
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3, gap: 1 }}>
          <Styled.BatchTag>Lotes ({product?.batchs?.length || 0})</Styled.BatchTag>
          <Styled.BatchTag>Procesos ({procesosCount})</Styled.BatchTag>
        </Box>
      </CardContent>
      <CardActions>
        <Styled.DetailsBtn onClick={() => navigate(`/product/${product._id}`)}>
          Ver Detalles
        </Styled.DetailsBtn>
      </CardActions>
    </Card>
  );
}
