//import { web3 } from "@openzeppelin/test-environment";
import { useEffect, useState } from "react";
import { useAuth } from "../providers/auth";
import { ControllerABI } from "../utils/ControllerABI";
import Web3 from "web3";
import { Receipt } from "../utils/types";
import { controllerContractAddress, isMainnet, rolesMainnet, rolesTestnet } from "../utils/constants";

// FIXME: To be revieweed
const contractAddress = controllerContractAddress;
const parsedAddress = Web3.utils.toChecksumAddress(contractAddress);

export const { admin, validator } = isMainnet ? rolesMainnet : rolesTestnet;

interface IUser {
  isValidator: boolean;
  isAdmin: boolean;
}

export const useContract = () => {
  const { handleLogout, account, magic, web3 } = useAuth();

  const controllerContract = new web3.eth.Contract(ControllerABI, parsedAddress);

  const [isFetching, setIsFetching] = useState(false);
  const [userRole, setUserRole] = useState<IUser | null>(null);

  const [isExecuting, setIsExecuting] = useState(false);

  useEffect(() => {
    getUserRole();
  }, [account]);

  const getUserRole = async () => {
    if (!web3) return;
    if (!account) return;

    try {
      setIsFetching(true);

      const [isValidator, isAdmin] = await Promise.all([
        controllerContract.methods.hasRole(validator, account).call(),
        controllerContract.methods.hasRole(admin, account).call(),
      ]); // Multicall
      setUserRole({ isValidator, isAdmin });
      return { isValidator, isAdmin };
    } catch (error) {
      console.error("Error getting user role:", error);
      return null;
    } finally {
      setIsFetching(false);
    }
  };

  const handleAddBatch = async ({
    ipfsString,
  }: {
    ipfsString: string;
  }): Promise<Receipt | null> => {
    if (!web3) return null;
    if (!account) return null;

    try {
      setIsExecuting(true);
      const gasPrice = await web3.eth.getGasPrice();
      const fastGasPrice = (+gasPrice * 1.3).toFixed(0); // Overpricing 30% gas price to avoid revert error

      console.log("Gas price: ", gasPrice);
      console.log("Fast gas price: ", fastGasPrice);

      const tx = await controllerContract.methods
        .addBatch(ipfsString)
        .send({ from: account, gasPrice: fastGasPrice })
        .on("transactionHash", function (e: any) {
          console.log("transactionHash ", e);
        })
        .on("receipt", function (receipt: any) {
          console.log("receipt ", receipt);
        });

      return tx;
    } catch (error) {
      console.error("Error adding batch role:", error);
      return null;
    } finally {
      setIsExecuting(false);
    }
  };

  const handleValidateBatch = async ({ batchId }: { batchId: string }): Promise<Receipt | null> => {
    if (!web3) return null;
    if (!account) return null;

    try {
      setIsExecuting(true);
      const gasPrice = await web3.eth.getGasPrice();
      const fastGasPrice = (+gasPrice * 1.3).toFixed(0); // Overpricing 30% gas price to avoid revert error

      const tx = await controllerContract.methods
        .validateBatch(batchId)
        .send({ from: account, gasPrice: fastGasPrice })
        .on("transactionHash", function (e: any) {
          console.log("transactionHash ", e);
        })
        .on("receipt", function (receipt: any) {
          console.log("receipt ", receipt);
        });

      return tx;
    } catch (error) {
      console.error("Error validating batch:", error);
      return null;
    } finally {
      setIsExecuting(false);
    }
  };

  const grantUserRole = async ({
    role,
    userAddress,
  }: {
    role: string;
    userAddress: `0x${string}`;
  }) => {
    if (!web3) return;
    if (!account) return;

    try {
      setIsExecuting(true);
      const gasPrice = await web3.eth.getGasPrice();
      const fastGasPrice = (+gasPrice * 1.3).toFixed(0); // Overpricing 30% gas price to avoid revert error

      console.log("Gas price: ", gasPrice);
      console.log("Fast gas price: ", fastGasPrice);

      console.log("Grant role to", userAddress, "with role", role, "from: ", account);
      const parsedUserAddress = Web3.utils.toChecksumAddress(userAddress);
      const tx = await controllerContract.methods
        .grantRole(role, parsedUserAddress)
        .send({ from: account, gasPrice: fastGasPrice })
        .on("transactionHash", function (e: any) {
          console.log("transactionHash ", e);
        })
        .on("receipt", function (receipt: any) {
          console.log("receipt ", receipt);
        });
      console.log("Transaction sent:", tx);
      const confirmation = await tx.wait();
      console.log("Transaction confirmed:", confirmation);
    } catch (error) {
      console.error("Error granting role:", error);
      return null;
    } finally {
      setIsExecuting(false);
    }
  };

  return {
    userRole,
    isFetching,
    isExecuting,
    handleAddBatch,
    handleValidateBatch,
    grantUserRole,
    getUserRole,
  };
};
