import { styled } from "@mui/material/styles";
import { Typography, Button } from "@mui/material";

export const ViewProductLayout = styled("div")(({ theme }) => ({
  paddingLeft: "4%",
  paddingRight: "4%",
  paddingTop: "2%",
  backgroundColor: "#F6F7FB",
  height: "100vh",
}));

export const Anchor = styled(Typography)(() => ({
  fontSize: "0.875rem",
  color: "#0029FF",
  cursor: "pointer",
}));

export const Title = styled(Typography)(() => ({
  fontWeight: "600",
  fontSize: "2rem",
  marginTop: ".5%",
}));

export const ProductKey = styled(Typography)(() => ({
  fontWeight: "600",
  fontSize: "0.813rem",
  marginTop: "2%",
  marginBottom: "2%",
}));

export const ProductValue = styled("span")(() => ({
  fontWeight: "400",
  fontSize: "0.813rem",
  marginTop: "2%",
  marginBottom: "2%",
}));

export const DetailsBtn = styled(Button)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: "500",
  color: "#FFFFFF",
  backgroundColor: "#127960",
  //border: "2px solid #679F38",
  borderRadius: "8px",
  textTransform: "none",
  //width: "100%",
  //marginBottom: "3%",
  "&:hover": {
    backgroundColor: "#127960",
    color: "white",
  },
}));

export const Dot = styled("div")({
  width: 10,
  height: 10,
  borderRadius: "50%",
  backgroundColor: "green",
  opacity: 0.7,
  position: "relative",
  animation: "glowing 2s infinite",
  animationName: "glowing",
  "@keyframes glowing": {
    "0%, 100%": {
      backgroundColor: "green",
    },
    "50%": {
      backgroundColor: "white",
    },
  },
});
