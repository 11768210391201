import React, { useEffect, useState } from "react";
import { collection, query, orderBy, onSnapshot, limit } from "firebase/firestore";
import { db } from "../../firebase";
import NavBar from "../../components/NavBar";
import * as Styled from "./styles";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";

const Transactions = () => {
  const isDesktop = useMediaQuery("(min-width:600px)");
  const [transactions, setTransactions] = useState<any[]>([]);

  useEffect(() => {
    const unsub = onSnapshot(
      query(collection(db, "records"), orderBy("created", "desc"), limit(7)),
      docu => {
        // updateData();
        const data = docu.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        // const sortedData = data.sort(function (x: any, y: any) {
        //   return y.created - x.created;
        // });
        console.log("Current data: ", data);
        setTransactions(data);
      },
    );
  }, []);

  function parseDate(timestamp: any) {
    if (!timestamp.seconds) return "N/A";
    return new Date(+timestamp.seconds * 1000).toLocaleDateString("en-US");
  }

  function parseTime(timestamp: any) {
    if (!timestamp.seconds) return "N/A";
    return new Date(+timestamp.seconds * 1000).toLocaleTimeString("en-US");
  }

  return (
    <>
      <NavBar />
      <Styled.DashboardLayout>
        {isDesktop ? (
          <Styled.Title>
            Transacciones (info on-chain) &nbsp; <Styled.Dot />
          </Styled.Title>
        ) : (
          <Styled.Title>
            Transacciones
            <br /> (info on-chain) &nbsp; <Styled.Dot />
          </Styled.Title>
        )}

        <br />
        <br />
        <>
          <Box
            sx={{
              width: "100%",
              backgroundColor: "#ffffff",
              borderRadius: "12px",
              padding: !isDesktop ? "5px" : "16px",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <TableContainer
              sx={{
                overflowX: isDesktop ? "unset" : "scroll",
                backgroundColor: "#ffffff",
                borderRadius: "12px",
                "&::-webkit-scrollbar": {
                  height: "12px",
                  width: "12px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#888",
                  borderRadius: "6px",
                },
              }}
            >
              <Table sx={{ minWidth: "max-content" }}>
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        color: "#000000",
                        backgroundColor: "#F6F7FB",
                        borderBottom: "1px solid #E2E8F0",
                      },
                    }}
                  >
                    <TableCell style={{ fontWeight: 600 }}>Proceso</TableCell>
                    <TableCell style={{ fontWeight: 600 }}>Categoría</TableCell>
                    <TableCell style={{ fontWeight: 600 }}>Productor</TableCell>
                    <TableCell style={{ fontWeight: 600 }}>Producto</TableCell>
                    <TableCell style={{ fontWeight: 600 }}>Fecha</TableCell>
                    <TableCell style={{ fontWeight: 600 }}>Hora</TableCell>
                    <TableCell style={{ fontWeight: 600 }}>Link</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions.map(row => (
                    <TableRow key={row.signed_data.step.step_name}>
                      <TableCell component="th" scope="row">
                        {row.signed_data.step.step_name}
                      </TableCell>
                      <TableCell>{row.signed_data.step.step_category ?? "N/A"}</TableCell>
                      <TableCell>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`https://my.agrojusto.com.ar/user/${row.user_data.user._id}`}
                        >
                          {row.user_data.user.profile.lastName}
                        </a>
                      </TableCell>
                      <TableCell>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`${process.env.REACT_APP_STORE_URL}/auth/mercado/productos/${row.signed_data.product.product_id}`}
                        >
                          {row.signed_data.product.product_name}
                        </a>
                      </TableCell>
                      <TableCell>{parseDate(row.created)}</TableCell>
                      <TableCell>{parseTime(row.created)}</TableCell>
                      <TableCell>
                        <a
                          href={row.ipfs_link.replace(
                            "https://ipfs.io/ipfs/",
                            "https://agrojusto-testnet.infura-ipfs.io/ipfs/",
                          )}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Link
                        </a>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
        <div></div>
      </Styled.DashboardLayout>
    </>
  );
};

export default Transactions;
