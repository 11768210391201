import { styled } from "@mui/material/styles";
import { Typography, Button } from "@mui/material";

export const ProductTitle = styled(Typography)(() => ({
  fontWeight: "600",
  fontSize: "1.125rem",
}));

export const ProductKey = styled(Typography)(() => ({
  fontWeight: "600",
  fontSize: "0.813rem",
  marginTop: "2%",
  marginBottom: "2%",
}));

export const ProductValue = styled("span")(() => ({
  fontWeight: "400",
  fontSize: "0.813rem",
  marginTop: "2%",
  marginBottom: "2%",
}));

export const DetailsBtn = styled(Button)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: "500",
  color: "#FFFFFF",
  backgroundColor: "#127960",
  //border: "2px solid #679F38",
  borderRadius: "8px",
  textTransform: "none",
  width: "100%",
  marginBottom: "3%",
  "&:hover": {
    backgroundColor: "#127960",
    color: "white",
  },
}));

export const BatchTag = styled("div")({
  backgroundColor: "#E9F0FE",
  color: "#266BF2",
  padding: "12px 30px",
  borderRadius: "4px",
  fontFamily: "Poppins, sans-seriff",
  fontSize: "12px",
  fontWeight: "400",
  width: "50%",
  textAlign: "center",
});
