import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const Confirm = styled(Button)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: "500",
  color: "#FFFFFF",
  backgroundColor: "#127960",
  //border: "2px solid #679F38",
  borderRadius: "8px",
  textTransform: "none",
  width: "100%",
  //marginBottom: "3%",
  "&:hover": {
    backgroundColor: "#127960",
    color: "white",
  },
}));

export const Cancel = styled(Button)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: "500",
  color: "#000000",
  backgroundColor: "#FFFFFF",
  border: "1px solid #127960",
  borderRadius: "8px",
  textTransform: "none",
  width: "100%",
  //marginBottom: "3%",
  "&:hover": {
    backgroundColor: "#FFFFFF",
    color: "000000",
  },
}));
