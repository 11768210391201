import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import { useState } from "react";
import { User__Type } from "../utils/types";

export const useDatabase = () => {

    const [isRegistering, setIsRegistering] = useState(false);

    const handleRegisterUser = async (user: User__Type) => {
        try {
            setIsRegistering(true);
            const isUserRegistered = await getDoc(doc(db, "users", user?.account));
            if (!isUserRegistered.exists()) {
                const docRef = await setDoc(doc(db, "users", user?.account), user);
                console.log("Uploaded to DB: ", docRef);
            } else {
                console.log("User already registered");
            }
        } catch (error) {
            console.error("Error uploading to DB: ", error);
        } finally {
            setIsRegistering(false);
        }
    }

    const handleFetchUsers = async () => {
        const users = await getDocs(collection(db, "users"));
        const usersList = users.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
            email: doc.data()?.user?.email || 'none'
        }));
        return usersList as { email: string, id: string, account: string, userRole: string }[];
    }

    return {
        isRegistering,
        handleRegisterUser,
        handleFetchUsers,
    }
}