import NavBar from "../../components/NavBar";
import * as Styled from "./styles";
import ArrowSvg from "../../assets/Arrow.svg";
import { useNavigate } from "react-router-dom";
import { admin, validator, useContract } from "../../hooks/useContract";
import { useEffect, useState } from "react";
import { Autocomplete, Box, Button, Input, TextField, Typography } from "@mui/material";
import { useDatabase } from "../../hooks/useDatabase";
import useDataStore from "../../store";

interface User {
  email: string;
  id: string;
  account: string;
  userRole: string;
}

const AdminPanel = () => {
  let navigate = useNavigate();

  const { grantUserRole, isExecuting } = useContract();
  const { userRole } = useDataStore();
  const { handleFetchUsers } = useDatabase();

  const [users, setUsers] = useState<User[]>([]);

  const [pickedUser, setPickedUser] = useState<User | null>(null);

  const [address, setAddress] = useState("");
  const [role, setRole] = useState("");

  useEffect(() => {
    handleFetchUsers().then(users => {
      setUsers(users);
    });
  }, []);

  if (!userRole?.isAdmin) {
    navigate("/");
  }

  return (
    <>
      <NavBar />
      <Styled.DashboardLayout>
        <Styled.Anchor onClick={() => navigate("/")}>
          <img src={ArrowSvg} alt="Arrow" style={{ verticalAlign: "middle" }} />
          Volver
        </Styled.Anchor>

        <Styled.Title>Panel de administración</Styled.Title>

        <Styled.Subtitle>Asignar Rol</Styled.Subtitle>
        <Box display={"flex"} flexDirection={"column"} gap={2} justifyContent={"space-between"}>
          <Box display={"flex"} flexDirection={"column"} width={"100%"} gap={2}>
            <Autocomplete
              options={users.map(user => ({ label: user?.email as any, value: user?.account }))}
              onChange={(event, newValue) => {
                setPickedUser(users.find(user => user.account === newValue?.value) || null);
                setAddress(newValue?.value || "");
              }}
              renderInput={params => <TextField {...params} label="User" />}
            />
            <Autocomplete
              options={[
                { label: "Admin", value: admin },
                { label: "Validador", value: validator },
              ]}
              onChange={(event, newValue) => setRole(newValue?.value || "")}
              renderInput={params => <TextField {...params} label="Rol" />}
            />

            <Box width={"100%"}>
              {pickedUser && (
                <>
                  <Styled.ProductKey>
                    Usuario: <Styled.ProductValue>{pickedUser?.email}</Styled.ProductValue>
                  </Styled.ProductKey>

                  <Styled.ProductKey>
                    Cuenta:{" "}
                    <Styled.ProductValue>
                      {pickedUser?.account?.slice(0, 6)}...{pickedUser?.account?.slice(-4)}
                    </Styled.ProductValue>
                  </Styled.ProductKey>

                  <Styled.ProductKey>
                    Rol (My Agrojusto):{" "}
                    <Styled.ProductValue>{pickedUser?.userRole}</Styled.ProductValue>
                  </Styled.ProductKey>
                </>
              )}
            </Box>

            <Styled.DetailsBtn
              onClick={() => grantUserRole({ role, userAddress: address as `0x${string}` })}
              disabled={isExecuting}
              variant="contained"
            >
              {isExecuting ? "Asignando..." : "Asignar"}
            </Styled.DetailsBtn>
          </Box>
        </Box>
      </Styled.DashboardLayout>
    </>
  );
};

export default AdminPanel;
