import { Button, CircularProgress, Input, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../providers/auth";
import * as Styled from "./styles";
import jwtDecode from "jwt-decode";
import useDataStore from "../../store";
import { toast } from "react-hot-toast";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import SensorsIcon from "@mui/icons-material/Sensors";
import { useDatabase } from "../../hooks/useDatabase";

const OnboardUser = () => {
  const { userEmail } = useParams();
  const navigate = useNavigate();
  const { handleConnect, account, setUserPayload } = useAuth();
  const { setUserData, setUserToken } = useDataStore();
  const [userPassword, setUserPassword] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const handleValidatePassword = async (event: React.FormEvent) => {
    event.preventDefault();
    if (userEmail) {
      setLoading(true);
      try {
        console.log(userEmail, userPassword);
        const userLogged = await fetch(`${process.env.REACT_APP_API_URL}/api/auth`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: userEmail,
            password: userPassword,
          }),
        });
        const res = await userLogged.json();
        console.log(res);

        let decoded = jwtDecode<any>(res.token);
        console.log("DECODED USER INFO: ", decoded);

        if (decoded?.user.role.code === "ADMIN") {
          const userRole = await fetch("https://api.agrojusto.com.ar/api/administrators/me", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: res.token,
            },
          });
          const userRes = await userRole.json();
          decoded.userRole = "ORGANIZATION";
          decoded.organizationId = userRes.organization._id;
          decoded.userProfile = `https://my.agrojusto.com.ar/auth/social/organization/${userRes.organization._id}`;
          console.log(
            "profile: ",
            `https://my.agrojusto.com.ar/auth/social/organization/${userRes.organization._id}`,
          );
        } else {
          const userRole = await fetch("https://api.agrojusto.com.ar/api/producers/me", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: res.token,
            },
          });
          const userRes = await userRole.json();
          decoded.userRole = "PRODUCER";
          decoded.producerId = "...";
          decoded.userProfile = `https://api.agrojusto.com.ar/api/producers/me`;
          console.log("profile: ", `https://api.agrojusto.com.ar/api/producers/me${userRes}`);
        }
        
        setUserData(decoded);
        setUserToken(res.token);

        const isConnected = await handleConnect({ email: userEmail, rawUserObject: decoded });

        if (isConnected) {
          navigate("/dashboard");
        } else {
          console.error("Authentication failed");
        }
      } catch (e) {
        toast.error("Usuario o contraseña incorrectos");
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Styled.OnboardUserLayout>
      <Styled.OnboardPasswordBox>
        <Styled.WelcomeText style={{ fontWeight: 800 }}>Bienvenido de nuevo</Styled.WelcomeText>
        <Styled.WelcomeText>{userEmail}</Styled.WelcomeText>
        <br />
        <Styled.DescriptionText>
          Por favor, ingresá tu contraseña de agrojusto para continuar:
        </Styled.DescriptionText>
        <br />
        <form onSubmit={handleValidatePassword} style={{ width: "100%", textAlign: "center" }}>
          <TextField
            type="password"
            variant="outlined"
            label="Contraseña"
            size="small"
            disabled={loading}
            onChange={e => setUserPassword(e.target.value)}
            sx={{ width: "80%" }}
          />
          <div style={{ marginTop: "1em" }}>
            <Styled.LoginButton type="submit" disabled={loading}>
              {!loading && "Ingresar"}
              {loading && <CircularProgress style={{ color: "white" }} size="1.3em" />}
            </Styled.LoginButton>
          </div>
        </form>
        <div style={{ marginTop: "2em" }}>
          <Styled.DescriptionText
            onClick={() => navigate("/transacciones")}
            style={{ color: "#689f38", fontWeight: 800, cursor: "pointer" }}
          >
            <SensorsIcon />
            &nbsp; Transacciones en vivo
          </Styled.DescriptionText>
        </div>
      </Styled.OnboardPasswordBox>
    </Styled.OnboardUserLayout>
  );
};

export default OnboardUser;
