import * as React from "react";
import { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useLocation, useNavigate } from "react-router-dom";
import useDataStore from "../../store";
import * as Styled from "./styles";
import Logo from "../../newLogo.png";

const pages = ["Inicio", "Validaciones", "Transacciones", "Admin"];
const settings = ["Cerrar sesion"];

function NavBar() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const navigate = useNavigate();
  const location = useLocation();

  const { userData, userRole } = useDataStore();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "white", boxShadow: "none", fontFamily: "Poppins" }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
            <img
              // src="/agrojusto-logo.png"
              src={Logo}
              alt="agrojusto"
              width={128}
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
            />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none", justifyContent: "end" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              style={{ color: "#689f38" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem onClick={() => navigate("/transacciones")}>Transacciones</MenuItem>
              {userRole?.isValidator && (
                <MenuItem onClick={() => navigate("/validaciones")}>Validaciones</MenuItem>
              )}
              {userRole?.isAdmin && <MenuItem onClick={() => navigate("/admin")}>Admin</MenuItem>}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex", justifyContent: "center" } }}>
            <Styled.NavButton
              isActive={location.pathname === "/" || location.pathname === "/dashboard"}
              onClick={() => navigate("/")}
            >
              Inicio
            </Styled.NavButton>
            {userRole?.isValidator && (
              <Styled.NavButton
                isActive={location.pathname === "/validaciones"}
                onClick={() => navigate("/validaciones")}
              >
                Validaciones
              </Styled.NavButton>
            )}
            <Styled.NavButton
              isActive={location.pathname === "/transacciones"}
              onClick={() => navigate("/transacciones")}
            >
              Transacciones
            </Styled.NavButton>
            {userRole?.isAdmin && (
              <Styled.NavButton
                isActive={location.pathname === "/admin"}
                onClick={() => navigate("/admin")}
              >
                Admin
              </Styled.NavButton>
            )}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            {userData && (
              <>
                <Tooltip title="Profile">
                  <IconButton onClick={() => navigate("/profile")} sx={{ p: 0 }}>
                    <Avatar alt="Remy Sharp" /* src="/images/default_avatar_user.png" */ />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                ></Menu>
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavBar;
