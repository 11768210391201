import { useState, useEffect, useRef } from "react";
import SearchIcon from "@mui/icons-material/Search";
import toast from "react-hot-toast";

interface MapComponentProps {
  initialLat: number;
  initialLng: number;
  setMarkerPosition: (lat: number, lng: number) => void;
}

const MapComponent = ({ initialLat, initialLng, setMarkerPosition }: MapComponentProps) => {
  const mapRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const markerRef = useRef<google.maps.Marker | null>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [address, setAddress] = useState("");
  const toastShown = useRef(false); // ✅ Prevent multiple toasts

  const latLng = { lat: initialLat, lng: initialLng };

  useEffect(() => {
    if (mapRef.current) {
      const newMap = new google.maps.Map(mapRef.current, {
        zoom: 10,
        center: latLng,
      });
      setMap(newMap);

      markerRef.current = new google.maps.Marker({
        position: latLng,
        map: newMap,
        draggable: true,
      });

      markerRef.current.addListener("dragend", async (event: google.maps.MapMouseEvent) => {
        if (event.latLng) {
          const lat = event.latLng.lat();
          const lng = event.latLng.lng();
          setMarkerPosition(lat, lng);

          const geocoder = new google.maps.Geocoder();
          geocoder.geocode({ location: { lat, lng } }, (results, status) => {
            if (status === "OK" && results?.length) {
              setAddress(results[0].formatted_address);
              toastShown.current = false; // ✅ Reset toast state on success
            } else {
              setAddress("");
              if (!toastShown.current) {
                toast.error("No se encontró una dirección.");
                toastShown.current = true; // ✅ Prevent duplicate toasts
              }
            }
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    if (map && inputRef.current) {
      const autocomplete = new google.maps.places.Autocomplete(inputRef.current);
      autocomplete.bindTo("bounds", map);

      autocomplete.addListener("place_changed", async () => {
        const place = autocomplete.getPlace();

        if (place.geometry?.location) {
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();
          map.setCenter({ lat, lng });
          map.setZoom(14);
          setMarkerPosition(lat, lng);
          markerRef.current?.setPosition({ lat, lng });
          setAddress(place.formatted_address || "");
          toastShown.current = false; // ✅ Reset toast state on success
        } else if (place.name) {
          try {
            const geocoder = new google.maps.Geocoder();
            const geocodeResults = await geocoder.geocode({ address: place.name });

            if (geocodeResults.results.length) {
              const location = geocodeResults.results[0].geometry.location;
              const lat = location.lat();
              const lng = location.lng();
              map.setCenter({ lat, lng });
              map.setZoom(14);
              setMarkerPosition(lat, lng);
              markerRef.current?.setPosition({ lat, lng });
              setAddress(geocodeResults.results[0].formatted_address || "");
              toastShown.current = false; // ✅ Reset toast state on success
            } else {
              throw new Error("ZERO_RESULTS");
            }
          } catch (error: any) {
            if (!toastShown.current) {
              if (error.message === "ZERO_RESULTS") {
                toast.error("No se encontraron resultados para esta dirección.");
              } else {
                toast.error("Error al buscar la ubicación. Intenta nuevamente.");
              }
              toastShown.current = true; // ✅ Prevent duplicate toasts
            }
          }
        } else {
          if (!toastShown.current) {
            toast.error("Ubicación no válida. Intenta nuevamente.");
            toastShown.current = true; // ✅ Prevent duplicate toasts
          }
        }
      });
    }
  }, [map, setMarkerPosition]);

  return (
    <div>
      <div style={{ position: "relative", width: "100%" }}>
        <SearchIcon
          style={{
            position: "absolute",
            left: "10px",
            top: "31%",
            transform: "translateY(-50%)",
            color: "gray",
          }}
        />
        <input
          ref={inputRef}
          type="text"
          placeholder="Buscar ..."
          value={address}
          onChange={e => setAddress(e.target.value)}
          style={{
            width: "100%",
            padding: "10px 10px 10px 40px",
            marginBottom: "5%",
            backgroundColor: "white",
            border: "2px solid #073026",
            borderRadius: "4px",
            outline: "none",
            fontSize: "16px",
            boxSizing: "border-box",
          }}
          onFocus={e => (e.target.style.borderColor = "#073026")}
          onBlur={e => (e.target.style.borderColor = "#073026")}
        />
      </div>

      <div ref={mapRef} style={{ height: "350px", width: "100%" }}></div>
    </div>
  );
};

export default MapComponent;
