import { styled } from "@mui/material/styles";
import { Typography, Button } from "@mui/material";

export const DashboardLayout = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "normal",
  gap: "1%",
  paddingLeft: "4%",
  paddingRight: "4%",
  paddingTop: "2%",
  backgroundColor: "#F6F7FB",
  height: "110vh",
  wordWrap: "break-word",
}));

export const Anchor = styled(Typography)(() => ({
  fontSize: "0.875rem",
  color: "#0029FF",
  cursor: "pointer",
}));

export const Title = styled(Typography)(() => ({
  fontWeight: "600",
  fontSize: "2rem",
}));

export const Subtitle = styled(Typography)(() => ({
  fontWeight: "600",
  fontSize: "1.3rem",
}));

export const Text = styled(Typography)(() => ({
  fontWeight: "400",
  fontSize: "1.3rem",
  color: "#141414",
}));

export const ProductKey = styled(Typography)(() => ({
  fontWeight: "600",
  fontSize: "0.813rem",
  marginTop: "1%",
  marginBottom: "1%",
}));

export const ProductValue = styled("span")(() => ({
  fontWeight: "400",
  fontSize: "0.813rem",
  marginTop: "1%",
  marginBottom: "1%",
}));

export const LogoutBtn = styled(Button)(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  color: "#000000",
  backgroundColor: "#FFFFFF",
  border: "1px solid #127960",
  borderRadius: "8px",
  textTransform: "none",
  width: "100%",
  //marginBottom: "3%",
  "&:hover": {
    backgroundColor: "#FFFFFF",
    color: "000000",
  },
}));
