import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Backdrop,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import {
  getFirestore,
  collection,
  addDoc,
  serverTimestamp,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { create as ipfsHttpClient } from "ipfs-http-client";
import { toast } from "react-hot-toast";
import * as Styled from "../UserProducts/EachProductCard/styles";
import { useContract } from "../../hooks/useContract";
import { publicIpfsUrl } from "../../utils/constants";
import InformationMessage from "../../components/InformationMessages/index";
const projectId = process.env.REACT_APP_PROJECT_ID;
const projectSecret = process.env.REACT_APP_PROJECT_SECRET;
const authorization = "Basic " + btoa(projectId + ":" + projectSecret);

const ipfs = ipfsHttpClient({
  url: "https://ipfs.infura.io:5001/api/v0",
  headers: {
    authorization,
  },
});

// Define the Batch type
type Batch = {
  _id: string;
  name: string;
  active: boolean;
  isMain: boolean;
};

type BatchTableProps = {
  batches: Batch[];
  isFetchingProcess: boolean;
};

const BatchTable: React.FC<BatchTableProps> = ({ batches, isFetchingProcess }) => {
  const isDesktop = useMediaQuery("(min-width:600px)");

  const db = getFirestore();

  const { handleAddBatch } = useContract();
  const [publishedBatches, setPublishedBatches] = useState<string[]>([]);
  const [isFetchingFirestore, setIsFetchingFirestore] = useState(true);

  const [isPublishing, setIsPublishing] = useState(false);

  useEffect(() => {
    const fetchPublishedBatches = async () => {
      setIsFetchingFirestore(true);
      const batchRef = collection(db, "batches");
      const querySnapshot = await getDocs(query(batchRef, where("published", "==", true)));
      setPublishedBatches(querySnapshot.docs.map(doc => doc.data().myAgrojustoBatchId));
      setIsFetchingFirestore(false);
    };
    fetchPublishedBatches();
  }, [db]);

  const publicateBatch = async (batch: Batch) => {
    try {
      setIsPublishing(true);
      const recordsRef = collection(db, "records");
      const q = query(recordsRef, where("signed_data.batch", "==", batch.name));
      const querySnapshot = await getDocs(q);

      const process = querySnapshot.docs.map(doc => {
        const recordData = doc.data();
        return {
          name: recordData.signed_data.step.step_name,
          hash: recordData.ipfs_link,
        };
      });

      const createdTimestamp = new Date().toISOString();

      const batchJsonObject = {
        title: "Batch",
        myAgrojustoBatchId: batch._id,
        name: batch.name,
        active: batch.active,
        isMain: batch.isMain,
        validated: false,
        created: createdTimestamp,
        process,
      };

      const result = await ipfs.add(JSON.stringify(batchJsonObject));
      const batchAdded = await handleAddBatch({ ipfsString: result.path });

      if (!batchAdded) {
        toast.error("Error al publicar lote. Intentalo nuevamente en unos minutos.");
        return;
      }

      await addDoc(collection(db, "batches"), {
        ipfs_link: `${publicIpfsUrl}/${result.path}`,
        name: batch.name,
        active: batch.active,
        isMain: batch.isMain,
        published: true,
        validated: false,
        created: serverTimestamp(),
        process,
        myAgrojustoBatchId: batch._id,
        blockchainBatchId: batchAdded.events.BatchCreated?.returnValues.batchId,
      });

      setPublishedBatches(prev => [...prev, batch._id]);

      toast.success("Lote publicado con éxito");
    } catch (error) {
      console.error("Error publishing batch: ", error);
      toast.error("Error al publicar lote");
    } finally {
      setIsPublishing(false);
    }
  };

  return (
    <>
      <InformationMessage isValidating={isPublishing} />

      {!isFetchingProcess ? (
        <TableContainer
          sx={{
            overflowX: isDesktop ? "unset" : "scroll",
            backgroundColor: "#ffffff",
            borderRadius: "12px",
            "&::-webkit-scrollbar": {
              height: "12px",
              width: "12px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
              borderRadius: "6px",
            },
          }}
        >
          <Table sx={{ minWidth: "max-content" }}>
            <TableHead>
              <TableRow
                sx={{
                  "& th": {
                    color: "#000000",
                    backgroundColor: "#F6F7FB",
                    borderBottom: "1px solid #E2E8F0",
                  },
                }}
              >
                <TableCell style={{ fontWeight: 600 }}>Nombre</TableCell>
                <TableCell style={{ fontWeight: 600 }}>Estado</TableCell>
                <TableCell style={{ fontWeight: 600 }}>Información</TableCell>
                <TableCell style={{ fontWeight: 600, width: "15%" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {batches.map(batch => (
                <TableRow key={batch._id}>
                  <TableCell>{batch.name}</TableCell>
                  <TableCell>{batch.active ? "Activo" : "Inactivo"}</TableCell>
                  <TableCell>{batch.isMain ? "Actual" : "N/A"}</TableCell>
                  <TableCell>
                    <Styled.DetailsBtn
                      variant="contained"
                      color="primary"
                      onClick={() => publicateBatch(batch)}
                      disabled={
                        isFetchingFirestore || isPublishing || publishedBatches.includes(batch._id)
                      }
                    >
                      {publishedBatches.includes(batch._id) ? "Lote Publicado" : "Publicar Lote"}
                    </Styled.DetailsBtn>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div>Obteniendo informacion...</div>
      )}
    </>
  );
};

export default BatchTable;
