import React, { useState, useEffect } from "react";
import { useAuth } from "../../providers/auth";
import { Magic } from "magic-sdk";
import { recoverPersonalSignature } from "@metamask/eth-sig-util";
import NoModal from "../../components/NoModal";
import { Button, Divider, TextField } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-hot-toast";
import jwt_decode from "jwt-decode";
import { create as ipfsHttpClient } from "ipfs-http-client";
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import NewProcessDialog from "../../components/NewProcessDialog";
import UserProducts from "../../components/UserProducts";
import useDataStore from "../../store";
import NavBar from "../../components/NavBar";
import Grid from "@mui/material/Grid";
import * as Styled from "./styles";

const projectId = process.env.REACT_APP_PROJECT_ID;
const projectSecret = process.env.REACT_APP_PROJECT_SECRET;
const authorization = "Basic " + btoa(projectId + ":" + projectSecret);

// Your web app's Firebase configuration

const ipfs = ipfsHttpClient({
  url: "https://ipfs.infura.io:5001/api/v0",
  headers: {
    authorization,
  },
});

const Dashboard = () => {
  const {  account, web3, userPayload } = useAuth();
  const { userToken, userData } = useDataStore();

  useEffect(() => {
    console.log("Dashboard..");
    console.log(userData);
  }, []);

  const [loading, setLoading] = useState<boolean>(true);

  const [message, setMessage] = useState<string>("");
  const [signed, setSigned] = useState<string>("");

  const [signing, setSigning] = useState(false);

  const [stepName, setStepName] = useState("");
  const [stepDescription, setStepDescription] = useState("");

  const [open, setOpen] = useState<boolean>(false);

  const signAndVerify = async (fullMessage: string) => {
    console.log("ORIGINAL: ", message);
    setSigning(true);

    const signedMessage = await web3.eth.personal.sign(fullMessage, account, "");

    console.log("signedMessage:", signedMessage);
    setSigned(signedMessage);
    // recover the public address of the signer to verify
    const recoveredAddress = recoverPersonalSignature({
      data: fullMessage,
      signature: signedMessage,
    });

    console.log("Recovered address: ", recoveredAddress);

    console.log(
      recoveredAddress.toLocaleLowerCase() === account.toLocaleLowerCase()
        ? "Signing success!"
        : "Signing failed!",
    );
    setSigning(false);
    toast.success("Mensaje firmado!");
    return signedMessage;
  };

  const handleAddProcess = async (item: any) => {
    console.log("ITEM: ", item);
    setOpen(true);
  };

  const handleSaveNewProcess = async () => {
    try {
      const docRef = await addDoc(collection(db, "steps"), {
        product_id: "1",
        step_name: stepName,
        step_description: stepDescription,
      });
      console.log("Document written with ID: ", docRef.id);
      toast.success("Registered!");
    } catch (e) {
      console.log(e);
      toast.error("Error");
    }
  };
  const handleUploadToIpfs = async () => {
    const myJson = {
      title: "Proceso",
      product: {
        product_name: "Tomate",
        product_id: "",
      },
      step: {
        step_name: stepName,
        step_description: stepDescription,
      },
    };

    const signature = await signAndVerify(JSON.stringify(myJson));

    console.log("SIGNATURE: ", signature);

    const token =
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZFRva2VuIjoiRFA4WXhENWZUOWhGZkRNdDI2MTlLUyIsInVzZXIiOnsiX2lkIjoiNjRiNGZhMzIwMTE5NmQwMDExOTQ5NTk0IiwiZW1haWwiOiJtYXRpYXNAbWVuZG96YWRldnMuY29tIiwicHJvZmlsZSI6eyJfaWQiOiI2NGI0ZmEzMjAxMTk2ZDAwMTE5NDk1OTIiLCJmaXJzdE5hbWUiOiJKb2huIiwibGFzdE5hbWUiOiJKb2huIn0sInJvbGUiOnsiX2lkIjoiYjUyMWNhYTZlMWRiODJlNWEwMWM5MjRhIiwibmFtZSI6IkFkbWluaXN0cmFkb3IiLCJjb2RlIjoiQURNSU4iLCJkZXNjcmlwdGlvbiI6IlJvbCBkZSBsb3MgYWRtaW5pc3RyYWRvcmVzIC4iLCJwZXJtaXNzaW9ucyI6W3siX2lkIjoiNzQ2MWQ0NzEwMTQyMjJjZDhmMGI0NjAxIiwibmFtZSI6IkNyZWFyIHVzdWFyaW9zIEFkbWluaXN0cmFkb3JlcyIsImNvZGUiOiJDUkVBVEVfQURNSU5fVVNFUlMiLCJkZXNjcmlwdGlvbiI6IlBlcm1pdGUgY3JlYXIgdXN1YXJpb3MgY29uIHJvbCBBZG1pbmlzdHJhZG9yLiJ9XX19LCJpYXQiOjE2ODk1ODIyNDYsImV4cCI6MTY4OTU4MjM2Nn0.xeYgLT0Pk527uI6TNiXCA8GklTuwuOTuBnfCsmDMomw";
    const decoded = jwt_decode(token);

    try {
      const result = await ipfs.add(JSON.stringify(myJson));
      console.log(`https://agrojusto-testnet.infura-ipfs.io/ipfs/${result.path}`);
      // toast.success("Credits applied!");
      const docRef = await addDoc(collection(db, "records"), {
        data: myJson,
        link: `https://agrojusto-testnet.infura-ipfs.io/ipfs/${result.path}`,
        user: decoded,
        signed_data: signature,
      });
      console.log("Document written with ID: ", docRef.id);
      toast.success("Registered!");
    } catch (e) {
      console.log("error");
      toast.error("Error");
    }
  };

  if (!userData) {
    window.location.href = "/onboard/user@email.com";
  }

  return (
    <>
      <NavBar />
      <Styled.DashboardLayout>
        <UserProducts />
      </Styled.DashboardLayout>

      <Backdrop sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }} open={signing}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <NewProcessDialog
        open={open}
        setOpen={setOpen}
        stepName={stepName}
        setStepName={setStepName}
        stepDescription={stepDescription}
        setStepDescription={setStepDescription}
        handleUploadToIpfs={handleUploadToIpfs}
        handleSaveNewProcess={handleSaveNewProcess}
      />
    </>
  );
};

export default Dashboard;
