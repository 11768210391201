import React, { useEffect, useState } from "react";
import useDataStore from "../../store";
import EachProductCard from "./EachProductCard";
import {
  CircularProgress,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import * as Styled from "./styles";

const UserProducts = () => {
  const { userData, userToken } = useDataStore();

  const [loading, setLoading] = useState<boolean>(true);
  const [products, setProducts] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string>("");

  useEffect(() => {
    console.log("Use Effect from UserProducts");
    fetchProductsList();
  }, []);

  const fetchProductsList = async () => {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/api/products/email/${userData?.user?.email}?itemsPerPage=999&page=1`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      },
    );

    const json = await res.json();
    setProducts(json.docs);
    setLoading(false);

    console.log("Productos: ", json.docs);
  };

  // Filter products by search and category
  const filteredProducts = products.filter(product => {
    const matchesSearch = product.title.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCategory = selectedCategory
      ? product.productType?.name === selectedCategory
      : true;
    return matchesSearch && matchesCategory;
  });

  return (
    <div>
      <Styled.Title>Mis Productos</Styled.Title>

      {/* Search & Filter Container */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          marginBottom: "1em",
          marginTop: "2em",
        }}
      >
        {/* Search Input */}
        <TextField
          variant="outlined"
          placeholder="Buscar producto..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: <SearchIcon style={{ color: "gray", marginRight: "8px" }} />,
          }}
          sx={{
            width: "50%",
            backgroundColor: "white",
            "& .MuiOutlinedInput-root": {
              "& fieldset": { borderColor: "#073026" },
              "&:hover fieldset": { borderColor: "#073026" },
              "&.Mui-focused fieldset": { borderColor: "#073026" },
            },
          }}
        />

        {/* Category Filter */}
        <FormControl
          variant="outlined"
          sx={{
            width: "40%",
            backgroundColor: "white",
            "& .MuiOutlinedInput-root": {
              "& fieldset": { borderColor: "#073026" },
              "&:hover fieldset": { borderColor: "#073026" },
              "&.Mui-focused fieldset": { borderColor: "#073026" },
            },
          }}
        >
          <InputLabel
            id="demo-simple-select-standard-label"
            sx={{
              color: "#073026",
              "&.Mui-focused": { color: "#073026" },
            }}
          >
            Filtrar por
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            label="Filtrar por"
            variant="outlined"
            value={selectedCategory}
            onChange={e => setSelectedCategory(e.target.value)}
            sx={{
              borderColor: "#073026",
              "& fieldset": { borderColor: "#073026" },
            }}
          >
            <MenuItem value="">
              <em>Todas las categorías</em>
            </MenuItem>
            {Array.from(new Set(products.map(p => p.productType?.name))).map(category => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div style={{ marginTop: "1em" }}>
        <Styled.GridContainer>
          {!loading &&
            filteredProducts.map((item, i) => <EachProductCard key={i} product={item} />)}
        </Styled.GridContainer>

        {loading && (
          <div
            style={{
              width: "100%",
              height: "200px",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              flexWrap: "wrap",
            }}
          >
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
};

export default UserProducts;
