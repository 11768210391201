import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { Typography, Box, Backdrop, LinearProgress } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import RecyclingIcon from "@mui/icons-material/Recycling";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";

// Define the type for the props of the component
interface DynamicTracingMessageProps {
  isValidating: boolean;
}

const messages = [
  {
    title: "Seguridad del producto",
    description: "Genera transparencia y credibilidad en la marca.",
    icon: <GppGoodOutlinedIcon fontSize="large" />,
    color: "#266BF2",
  },
  {
    title: "Confianza del consumidor",
    description: "Genera transparencia y credibilidad en la marca.",
    icon: <InsertEmoticonIcon fontSize="large" />,
    color: "#FF8400",
  },
  {
    title: "Sostenibilidad",
    description: "Garantiza un impacto ambiental responsable.",
    icon: <RecyclingIcon fontSize="large" />,
    color: "#093D30",
  },
  {
    title: "Reducción de costos",
    description: "Minimiza pérdidas y desperdicio de producto.",
    icon: <LocalAtmIcon fontSize="large" />,
    color: "#4E1CB8",
  },
  {
    title: "Diferencia competitiva",
    description: "Destaca sobre la competencia con valor agregado",
    icon: <CampaignOutlinedIcon fontSize="large" />,
    color: "#C80F3A",
  },
];

const DynamicTracingMessage: React.FC<DynamicTracingMessageProps> = ({ isValidating }) => {
  const isDesktop = useMediaQuery("(min-width:600px)");

  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex(prevIndex => (prevIndex + 1) % messages.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const progressInterval = setInterval(() => {
      setProgress(prev => (prev < 90 ? prev + 2 : prev));
    }, 500);

    return () => clearInterval(progressInterval);
  }, [currentMessageIndex]);

  const currentMessage = messages[currentMessageIndex];

  return (
    <Backdrop open={isValidating} sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}>
      <div
        style={{
          textAlign: "center",
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "8px",
        }}
      >
        <Typography sx={{ fontWeight: 600, fontSize: "1.5rem", mb: 2, color: "#101828" }}>
          Procesando Lote
        </Typography>

        <Box sx={{ width: "100%", mb: 2 }}>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
              mx: 4,
              height: 8,
              borderRadius: 4,
              backgroundColor: "#E4E4E4",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#0C5241",
              },
            }}
          />
        </Box>

        <Typography sx={{ mb: 4, fontSize: "0.875rem", color: "#667085" }}>
          Este proceso puede demorar unos minutos.
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            border: "1px solid #D92D20",
            borderRadius: "8px",
            padding: "14px 20px",
            mb: 2,
            backgroundColor: "#D92D200A",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 1,
              mt: 1,
              flexDirection: isDesktop ? "" : "column",
            }}
          >
            <ErrorIcon sx={{ color: "#D92D20", marginRight: "8px" }} />
            <Typography sx={{ fontWeight: 700, color: "#D92D20", marginRight: "4px" }}>
              Importante:{" "}
            </Typography>
            <Typography sx={{ fontWeight: 400, color: "black" }}>
              No Cierres esta ventana mientras se completa el proceso.
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            border: `1px solid #F6F7FB`,
            backgroundColor: "#F6F7FB",
            borderRadius: "8px",
            padding: "14px 14px",
            mb: 2,
            flexDirection: "column",
          }}
        >
          <Typography sx={{ fontWeight: 500, color: "#667085", mt: 2 }}>
            Tips sobre la trazabilidad de tus productos
          </Typography>
          <Box sx={{ color: currentMessage.color, mt: 2 }}>{currentMessage.icon}</Box>
          <Typography
            sx={{ fontWeight: 500, fontSize: "1.25rem", color: currentMessage.color, mt: 2 }}
          >
            {currentMessage.title}
          </Typography>
          <Typography sx={{ fontSize: "0.875rem", color: "#667085", mb: 3 }}>
            {currentMessage.description}
          </Typography>
        </Box>
      </div>
    </Backdrop>
  );
};

export default DynamicTracingMessage;
