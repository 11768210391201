// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import MapComponent from "../GoogleMaps/MapComponent";
import toast from "react-hot-toast";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  Box,
  Typography,
  Tooltip,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import SearchIcon from "@mui/icons-material/Search";
import { useMediaQuery } from "@mui/material";
import * as Styled from "./styles";
import { useDropzone } from "react-dropzone";
import { useCallback } from "react";

export default function NewProcessDialog({
  open,
  setOpen,
  stepCategory,
  setStepCategory,
  stepName,
  setStepName,
  stepBatch,
  setStepBatch,
  stepDescription,
  setStepDescription,
  stepResponsible,
  setStepResponsible,
  markerPosition,
  handleMarkerDrag,
  setImagePreview,
  imagePreview,
  handleImageChange,
  handleUploadToIpfs,
  handleSaveNewProcess,
  batches,
  selectedDateTime,
  handleDateTimeChange,
}: any) {
  const isDesktop = useMediaQuery("(min-width:600px)");

  const render = (status: Status) => <h1 style={{ textAlign: "center" }}>Cargando</h1>;

  const steps = ["Datos generales", "Ubicación", "Imagen"];

  const [activeStep, setActiveStep] = React.useState(0);

  const [skipped, setSkipped] = React.useState(new Set<number>());

  const isStepOptional = (step: number) => {
    return step === 2;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (
      activeStep === 0 &&
      (stepCategory === "" ||
        stepName === "" ||
        stepBatch === "" ||
        stepDescription === "" ||
        stepResponsible === "")
    ) {
      toast("Por favor, completa todos los campos.", {
        icon: "ℹ️",
      });
      return;
    }
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(prevSkipped => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    handleUploadToIpfs();
    setOpen(false);
    handleReset();
  };

  const onDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setImagePreview(reader.result);
      reader.readAsDataURL(file);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: false,
    onDrop,
  });

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Box
            sx={{
              width: isDesktop ? "25vw" : "100%",
              minHeight: "450px", // Ensure all steps have the same height
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden", // Prevent content from overflowing
            }}
          >
            <Box
              sx={{
                width: "100%",
                minHeight: "350px", // Consistent height for all steps
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {activeStep === 0 && (
                <Box
                  sx={{
                    height: "100%", // Ensures content is aligned properly
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{ color: "#127960", fontWeight: 500, fontSize: "0.75rem", mb: 1 }}
                  >
                    Paso 1 de 3
                  </Typography>
                  <Typography
                    sx={{ color: "#101828", fontWeight: 600, fontSize: "1.125rem", mb: 4 }}
                  >
                    Datos generales
                  </Typography>

                  <Tooltip title="Seleccione on tipo de proceso" arrow>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": { borderColor: "#073026" },
                          "&:hover fieldset": { borderColor: "#073026" },
                          "&.Mui-focused fieldset": { borderColor: "#073026" },
                        },
                      }}
                    >
                      <InputLabel
                        required
                        id="demo-simple-select-standard-label"
                        sx={{
                          color: "#073026",
                          "&.Mui-focused": { color: "#073026" },
                        }}
                      >
                        Tipo de Proceso
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        variant="outlined"
                        fullWidth
                        label="Tipo de Proceso "
                        value={stepCategory}
                        onChange={e => setStepCategory(e.target.value)}
                        sx={{
                          borderColor: "#073026",
                          "& fieldset": { borderColor: "#073026" },
                        }}
                      >
                        <MenuItem value="">
                          <em>Seleccionar una</em>
                        </MenuItem>
                        <MenuItem value={"Producción"}>Producción</MenuItem>
                        <MenuItem value={"Almacenamiento"}>Almacenamiento</MenuItem>
                        <MenuItem value={"Transporte"}>Transporte</MenuItem>
                        <MenuItem value={"Labranza"}>Labranza</MenuItem>
                        <MenuItem value={"Siembra"}>Siembra</MenuItem>
                        <MenuItem value={"Riego"}>Riego</MenuItem>
                        <MenuItem value={"Fertilización"}>Fertilización</MenuItem>
                        <MenuItem value={"Aplicación"}>Aplicación</MenuItem>
                        <MenuItem value={"Monitoreo"}>Monitoreo</MenuItem>
                        <MenuItem value={"Cosecha"}>Cosecha</MenuItem>
                        <MenuItem value={"Prácticas de Género"}>Prácticas de Género</MenuItem>
                        <MenuItem value={"Prácticas Sostenibles"}>Prácticas Sostenibles</MenuItem>
                        <MenuItem value={"Prácticas Regenerativas"}>
                          Prácticas Regenerativas
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Tooltip>

                  <Tooltip title="Asigne un nombre al proceso" arrow>
                    <TextField
                      required
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Nombre del proceso"
                      type="email"
                      fullWidth
                      variant="outlined"
                      value={stepName}
                      onChange={e => setStepName(e.target.value)}
                      sx={{
                        "& label": { color: "#073026" },
                        "& label.Mui-focused": { color: "#073026" },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": { borderColor: "#073026" },
                          "&:hover fieldset": { borderColor: "#073026" },
                          "&.Mui-focused fieldset": { borderColor: "#073026" },
                        },
                      }}
                    />
                  </Tooltip>

                  <Tooltip
                    title="Debe ingresar la fecha en que ocurrió, sin importar si fue antes de la fecha de carga"
                    arrow
                  >
                    <TextField
                      id="datetime-local"
                      label="Fecha y hora del proceso"
                      type="datetime-local"
                      value={selectedDateTime}
                      onChange={handleDateTimeChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      sx={{
                        mb: 1,
                        "& label": { color: "#073026" },
                        "& label.Mui-focused": { color: "#073026" },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": { borderColor: "#073026" },
                          "&:hover fieldset": { borderColor: "#073026" },
                          "&.Mui-focused fieldset": { borderColor: "#073026" },
                        },
                      }}
                    />
                  </Tooltip>

                  {batches && batches.length > 0 ? (
                    <>
                      <Tooltip title="Seleccione uno de sus lotes generados" arrow>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": { borderColor: "#073026" },
                              "&:hover fieldset": { borderColor: "#073026" },
                              "&.Mui-focused fieldset": { borderColor: "#073026" },
                            },
                          }}
                        >
                          <InputLabel
                            required
                            id="demo-simple-select-standard-label"
                            sx={{
                              color: "#073026",
                              "&.Mui-focused": { color: "#073026" },
                            }}
                          >
                            Lote
                          </InputLabel>

                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            variant="outlined"
                            fullWidth
                            label="Lote "
                            value={stepBatch}
                            onChange={e => setStepBatch(e.target.value)}
                            sx={{
                              borderColor: "#073026",
                              "& fieldset": { borderColor: "#073026" },
                            }}
                          >
                            <MenuItem value="">
                              <em>Seleccione un lote</em>
                            </MenuItem>
                            {batches?.map((item: any) => (
                              <MenuItem key={item._id} value={item.name}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Tooltip>
                    </>
                  ) : (
                    <Box sx={{ mb: 5 }}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel sx={{ color: "red" }}>No hay lotes disponibles*</InputLabel>
                      </FormControl>
                    </Box>
                  )}

                  {/*  */}
                  <Tooltip
                    title="Describa de manera resumida las fases principales del proceso"
                    arrow
                  >
                    <TextField
                      required
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Descripción del proceso"
                      type="email"
                      fullWidth
                      variant="outlined"
                      value={stepDescription}
                      onChange={e => setStepDescription(e.target.value)}
                      sx={{
                        "& label": { color: "#073026" },
                        "& label.Mui-focused": { color: "#073026" },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": { borderColor: "#073026" },
                          "&:hover fieldset": { borderColor: "#073026" },
                          "&.Mui-focused fieldset": { borderColor: "#073026" },
                        },
                      }}
                    />
                  </Tooltip>

                  <Tooltip
                    title="Ingresa el nombre de la persona encargada de realizar este proceso"
                    arrow
                  >
                    <TextField
                      required
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Realizador del proceso"
                      type="email"
                      fullWidth
                      variant="outlined"
                      value={stepResponsible}
                      onChange={e => setStepResponsible(e.target.value)}
                      sx={{
                        "& label": { color: "#073026" },
                        "& label.Mui-focused": { color: "#073026" },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": { borderColor: "#073026" },
                          "&:hover fieldset": { borderColor: "#073026" },
                          "&.Mui-focused fieldset": { borderColor: "#073026" },
                        },
                      }}
                    />
                  </Tooltip>

                  <Box style={{ marginBottom: "13%" }}></Box>
                </Box>
              )}
              {activeStep === 1 && (
                <Box
                  sx={{
                    height: "100%", // Ensures content is aligned properly
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{ color: "#127960", fontWeight: 500, fontSize: "0.75rem", mb: 1 }}
                  >
                    Paso 2 de 3
                  </Typography>
                  <Typography
                    sx={{ color: "#101828", fontWeight: 600, fontSize: "1.125rem", mb: 2 }}
                  >
                    Localización Geográfica
                  </Typography>

                  <Typography
                    sx={{ color: "#999999", fontWeight: 400, fontSize: "0.75rem", mb: 2 }}
                  >
                    Busqué la dirección o arraste el marcador en el mapa para marcar la ubicación.{" "}
                  </Typography>
                  {/* 
                  <TextField
                    disabled
                    fullWidth
                    variant="outlined"
                    placeholder="Buscar ..."
                    //value={searchTerm}
                    //onChange={e => setSearchTerm(e.target.value)}
                    InputProps={{
                      startAdornment: <SearchIcon style={{ color: "gray", marginRight: "8px" }} />,
                    }}
                    sx={{
                      marginBottom: "5%",
                      backgroundColor: "white",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { borderColor: "#073026" },
                        "&:hover fieldset": { borderColor: "#073026" },
                        "&.Mui-focused fieldset": { borderColor: "#073026" },
                      },
                    }}
                  /> */}
                  {/*                   
                  <Wrapper render={render} apiKey={process.env.REACT_APP_MAPS_API_KEY}>
                    <MapComponent
                      initialLat={markerPosition?.lat}
                      initialLng={markerPosition?.lng}
                      onMarkerDrag={handleMarkerDrag}
                    />
                  </Wrapper> */}
                  {/*  @ts-expect-error */}
                  <Wrapper
                    render={render}
                    apiKey={process.env.REACT_APP_MAPS_API_KEY}
                    libraries={["places"]} // Ensure the places library is loaded
                  >
                    <MapComponent
                      initialLat={markerPosition?.lat}
                      initialLng={markerPosition?.lng}
                      setMarkerPosition={handleMarkerDrag}
                    />
                  </Wrapper>
                </Box>
              )}

              {activeStep === 2 && (
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{ color: "#127960", fontWeight: 500, fontSize: "0.75rem", mb: 1 }}
                  >
                    Paso 3 de 3
                  </Typography>
                  <Typography
                    sx={{ color: "#101828", fontWeight: 600, fontSize: "1.125rem", mb: 5 }}
                  >
                    Imagen <span style={{ fontWeight: 500 }}>(Opcional)</span>
                  </Typography>

                  {/* Drop Zone */}
                  <Box
                    {...getRootProps()}
                    sx={{
                      width: "auto",
                      height: "420px",
                      border: "1px solid #EAECF0",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      overflow: "hidden",
                      backgroundColor: "white",
                      cursor: "pointer",
                      borderRadius: "8px",
                      mb: 3,
                      padding: isDesktop ? 0 : 4,
                      boxSizing: "border-box",
                    }}
                  >
                    <input {...getInputProps()} />
                    {imagePreview ? (
                      <img
                        src={imagePreview}
                        alt="Preview"
                        style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
                      />
                    ) : (
                      <Box sx={{ textAlign: "center" }}>
                        <CloudUploadIcon sx={{ color: "#475467" }} fontSize="large" />
                        <Typography color="#6941C6" sx={{ fontWeight: 500, fontSize: "0.875rem" }}>
                          Haga click aquí
                        </Typography>
                        <Typography color="#667085" sx={{ fontWeight: 400, fontSize: "0.75rem" }}>
                          o arrastre su imagen.
                        </Typography>
                        <Typography color="#667085" sx={{ fontWeight: 400, fontSize: "0.75rem" }}>
                          (SVG, PNG, JPG, GIF - max. 800x400px)
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}

              <Box sx={{ display: "flex", flexDirection: "row", pt: 2, mt: 2 }}>
                <Styled.Cancel
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Atras
                </Styled.Cancel>
                <Box sx={{ flex: "1 1 auto" }} />

                <Styled.Confirm
                  onClick={activeStep === steps.length - 1 ? handleConfirm : handleNext}
                >
                  {activeStep === steps.length - 1 ? "Finalizar" : "Continuar"}
                </Styled.Confirm>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
