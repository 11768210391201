import React, { ChangeEvent, useState, useEffect } from "react";
import AWS from "aws-sdk";
import { useParams, useNavigate } from "react-router-dom";
import useDataStore from "../../store";
import { useAuth } from "../../providers/auth";
import { recoverPersonalSignature } from "@metamask/eth-sig-util";
import { toast } from "react-hot-toast";
import { create as ipfsHttpClient } from "ipfs-http-client";
import { db } from "../../firebase";
import {
  doc,
  collection,
  addDoc,
  query,
  where,
  getDocs,
  serverTimestamp,
  orderBy,
  deleteDoc,
} from "firebase/firestore";
import * as Styled from "./styles";
import NavBar from "../../components/NavBar";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  Typography,
  Box,
} from "@mui/material";
import NewProcessDialog from "../../components/NewProcessDialog";
import NewBatchDialog from "../../components/NewBatchDialog";
import BatchTable from "../../components/BatchsTable/index";
import ArrowSvg from "../../assets/Arrow.svg";
import { useMediaQuery } from "@mui/material";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const projectId = process.env.REACT_APP_PROJECT_ID;
const projectSecret = process.env.REACT_APP_PROJECT_SECRET;
const authorization = "Basic " + btoa(projectId + ":" + projectSecret);
const amazonURL = process.env.REACT_APP_IS_AMAZON_BUCKET_URL;

const ipfs = ipfsHttpClient({
  url: "https://ipfs.infura.io:5001/api/v0",
  headers: {
    authorization,
  },
});

const ViewProduct = () => {
  const isDesktop = useMediaQuery("(min-width:600px)");

  const { productId } = useParams();
  const { userToken, userData, account } = useDataStore();
  const { web3 } = useAuth();
  let navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [newBatch, setNewBatch] = useState(false);

  const [isFetching, setIsFetching] = useState<boolean>(true);

  const [product, setProduct] = useState<any>([]);
  const [process, setProcess] = useState<any[]>([]);
  const [batches, setBatches] = useState<any[]>([]);

  const [isFetchingProcess, setIsFetchingProcess] = useState<boolean>(true);

  const [newBatchName, setNewBatchName] = useState<string>("");

  const [stepCategory, setStepCategory] = useState<string>("");
  const [stepBatch, setStepBatch] = useState<string>("");
  const [stepName, setStepName] = useState<string>("");
  //  date and time
  const [selectedDateTime, setSelectedDateTime] = useState(getFormattedDateTime());

  // Function to get the current date and time in the required format
  function getFormattedDateTime() {
    const currentDate = new Date();
    const options = { timeZone: "America/Argentina/Buenos_Aires" };
    const formattedDate = currentDate.toLocaleString("en-US", {
      ...options,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    return formattedDate.replace(/(\d+)\/(\d+)\/(\d+), (\d+):(\d+)/, "$3-$1-$2T$4:$5");
  }

  const [stepDescription, setStepDescription] = useState<string>("");
  const [stepResponsible, setStepResponsible] = useState<string>("");
  const [stepLocation, setStepLocation] = useState<string>("");
  const [imageS3Locations, setImageS3Location] = useState<string>("");

  const [signed, setSigned] = useState<string>("");

  const [signing, setSigning] = useState<boolean>(false);

  const [value, setValue] = useState(0);

  const defaultMarkerPosition = {
    lat: -32.88427734257276,
    lng: -68.89183466305856,
  };
  const [markerPosition, setMarkerPosition] = useState(defaultMarkerPosition);

  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);

  const handleMarkerDrag = (lat: number, lng: number) => {
    setMarkerPosition({ lat, lng });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // Handler function to update state when date and time are changed
  const handleDateTimeChange = (event: any) => {
    setSelectedDateTime(event.target.value);
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      // Read the selected image file and convert it to a data URL
      setFileName(file.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        const dataURL = reader.result as string;
        setImagePreview(dataURL);
      };
      reader.readAsDataURL(file);
    } else {
      setFileName(null);
      setImagePreview(null);
    }
  };

  //UPLOAD IMAGE TO AGROJUSTO

  const convertDataURLToFile = (dataURL: string, fileName: string): File => {
    const byteString = atob(dataURL.split(",")[1]); // decode base64
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    // Populate array buffer
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    // Create a File object
    return new File([arrayBuffer], fileName, { type: "image/png" });
  };

  const handleImageUpload = async (imageFile: File): Promise<string | null> => {
    try {
      const userEmail = encodeURIComponent(userData.user.email);

      const response = await fetch(
        `${amazonURL}?path=blockchain%2Fusers%2F${userEmail}%2Fprocesses%2F${Date.now()}.png&fileType=image/png`,
      );

      if (!response.ok) {
        throw new Error(`Failed to get signed URL: ${response.status} ${response.statusText}`);
      }

      // Get the signed URL from API response
      const signedUrlResponse = await response.json();
      //console.log("📌 Full API Response:", signedUrlResponse);

      const uploadUrl = signedUrlResponse.uploadURL;

      if (!uploadUrl) {
        console.error("⚠️ Signed URL is missing from API response!");
        return null;
      }

      //console.log("✅ Using Signed URL:", uploadUrl);

      // Upload the file using PUT request
      const uploadResponse = await fetch(uploadUrl, {
        method: "PUT",
        body: imageFile,
        headers: { "Content-Type": "image/png" },
      });

      if (!uploadResponse.ok) {
        throw new Error(`Upload failed: ${uploadResponse.status} ${uploadResponse.statusText}`);
      }

      //console.log("✅ Image uploaded successfully!");

      // Return the image URL (without query params for a cleaner URL)
      return uploadUrl.split("?")[0];
    } catch (error) {
      console.error("❌ Error uploading image:", error);
      return null;
    }
  };

  useEffect(() => {
    console.log(account);
    console.log("UDATA: ", userData);
    initData();
  }, []);

  const initData = async () => {
    setIsFetching(true);
    await fetchProduct();
    await fetchProductProcesses();
    // await fetchAllProducts();
    setIsFetching(false);
  };

  const fetchAllProducts = async () => {
    const productsQuery = query(
      collection(db, "records"),
      // orderBy("created", "asc")
    );
    const queryRes = await getDocs(productsQuery);
    const data = queryRes.docs.map(doc => ({ ...doc.data(), id: doc.id }));

    console.log("ALL PRODUCTS > ", data);
  };

  const fetchProduct = async () => {
    console.log("Fetching...");
    const res = await fetch(`https://api.agrojusto.com/api/products/${productId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });
    const json = await res.json();

    const locationLat =
      json.organization?.location?.lat !== null && json.organization?.location?.lat !== undefined
        ? json.organization.location.lat
        : defaultMarkerPosition.lat;

    const locationLng =
      json.organization?.location?.lng !== null && json.organization?.location?.lng !== undefined
        ? json.organization.location.lng
        : defaultMarkerPosition.lng;
    setMarkerPosition({ lat: locationLat, lng: locationLng });

    setProduct(json);
  };

  const fetchProductProcesses = async () => {
    console.log(productId);
    const productsQuery = query(
      collection(db, "records"),
      where("product_id", "==", productId),
      // orderBy("created", "asc")
    );
    const queryRes = await getDocs(productsQuery);
    const data: any = queryRes.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
    }));

    const sortedData = data.sort(function (x: any, y: any) {
      return x.created - y.created;
    });

    const batches = sortedData
      .filter((item: any, index: number) => {
        return item.signed_data.batch;
      })
      .map((item: any) => {
        return item.signed_data.batch;
      });

    const sortedBatches = batches.filter(function (item: any, pos: number) {
      return batches.indexOf(item) == pos;
    });

    console.log("BATCHES: ", sortedBatches);
    setBatches(sortedBatches);

    setProcess(sortedData);
    setIsFetchingProcess(false);
  };

  const signAndVerify = async (fullMessage: string) => {
    setSigning(true);

    const signedMessage = await web3.eth.personal.sign(fullMessage, account, "");

    console.log("signedMessage:", signedMessage);
    setSigned(signedMessage);
    // recover the public address of the signer to verify
    const recoveredAddress = recoverPersonalSignature({
      data: fullMessage,
      signature: signedMessage,
    });

    console.log("Recovered address: ", recoveredAddress);

    console.log(
      recoveredAddress.toLocaleLowerCase() === account?.toLocaleLowerCase()
        ? "Signing success!"
        : "Signing failed!",
    );
    setSigning(false);
    // toast.success("Mensaje firmado y encriptado!");
    return signedMessage;
  };

  const handleUploadToIpfs = async () => {
    let imageS3Location: string | null = null; // Allow null here

    if (imagePreview) {
      try {
        // Convert imagePreview (data URL) to a File object
        const imageFile = convertDataURLToFile(imagePreview, `${Date.now()}.png`);

        // Upload the image file to S3 and get the URL or null if not uploaded
        imageS3Location = await handleImageUpload(imageFile); // This can return a string or null
      } catch (error) {
        console.error("❌ Error uploading image to S3:", error);
        // If upload fails, imageS3Location will remain null
      }
    }

    const myJson = {
      title: "Proceso",
      product: {
        product_name: product?.title,
        product_id: product?._id,
        product_description: product?.description,
      },
      batch: stepBatch,
      step: {
        step_category: stepCategory,
        step_name: stepName,
        step_description: stepDescription,
        step_responsible: stepResponsible,
        step_location: product?.producer?.profile?.address?.province || "Mendoza",
        step_date_time: selectedDateTime,
        step_coordinates: markerPosition,
        step_image: imageS3Location,
      },
    };

    const signature = await signAndVerify(JSON.stringify(myJson));
    console.log("SIGNATURE: ", signature);

    try {
      const result = await ipfs.add(JSON.stringify(myJson));
      console.log(
        "Uploaded to IPFS: ",
        `https://agrojusto-testnet.infura-ipfs.io/ipfs/${result.path}`,
      );

      const docRef = await addDoc(collection(db, "records"), {
        product_id: product?._id,
        signed_data: myJson,
        ipfs_link: `https://ipfs.io/ipfs/${result.path}`,
        user_data: userData,
        signature: signature,
        created: serverTimestamp(),
        step_date_time: selectedDateTime,
        location: markerPosition,
        s3Image: imageS3Location,
      });

      console.log("Uploaded to DB: ", docRef.id);

      await initData();
      setOpen(false);
      setStepName("");
      setStepDescription("");
      setStepResponsible("");
      setStepBatch("");
      setImagePreview(null);
      setImageS3Location("");
      setMarkerPosition({
        lat: -32.88427734257276,
        lng: -68.89183466305856,
      });
    } catch (e) {
      console.log("error", e);
      // toast.error("Error");
    }
  };

  const handleDeleteProcess = async (item: any) => {
    console.log("deleting...", item);
    try {
      const deleted = await deleteDoc(doc(db, "records", item.id));
      console.log("deleted", deleted);
      await initData();
    } catch (e) {
      console.log(e);
    }
  };

  const handleUploadNewBatch = async (newProcess: any) => {
    const myJson = {
      title: "Proceso",
      product: {
        product_name: newProcess?.signed_data.product?.product_name,
        product_id: newProcess?.signed_data?.product?.product_id,
        product_description: newProcess?.signed_data?.product?.product_description,
      },
      batch: newBatchName,
      step: {
        step_category: newProcess?.signed_data?.step?.step_category,
        step_name: newProcess?.signed_data?.step?.step_name,
        step_description: newProcess?.signed_data?.step?.step_description,
        step_responsible: newProcess?.signed_data?.step?.step_responsible,
        step_location: newProcess?.signed_data?.step?.step_location,
      },
    };

    console.log("LAST STEP: ", myJson);

    const signature = await signAndVerify(JSON.stringify(myJson));

    try {
      const result = await ipfs.add(JSON.stringify(myJson));
      console.log(
        "Uploaded to IPFS: ",
        `https://agrojusto-testnet.infura-ipfs.io/ipfs/${result.path}`,
      );

      const docRef = await addDoc(collection(db, "records"), {
        product_id: product?._id,
        signed_data: myJson,
        ipfs_link: `https://ipfs.io/ipfs/${result.path}`,
        user_data: userData,
        signature: signature,
        created: serverTimestamp(),
      });

      console.log("Uploaded to DB: ", docRef.id);

      console.log("CONTINUE WITHG OTHER??");
    } catch (e) {
      console.log("error", e);
    }
  };

  const handleCreateNewBatch = async (batch: string) => {
    console.log("Creating...");
    console.log("Fetching batch...");
    const batchProcess = process.filter((item: any) => item.signed_data.batch === batch);

    try {
      const createNewBatch = await Promise.all(
        batchProcess.map(async (item: any) => {
          await handleUploadNewBatch(item);
        }),
      );
      toast.success("Nuevo lote generado con éxito");
      await initData();
    } catch (e) {
      console.log("ERROR: ", e);
      toast.error("Error al crear lote. Reintenta nuevamente.");
    }
  };

  return (
    <>
      <NavBar />
      <Styled.ViewProductLayout>
        <Styled.Anchor onClick={() => navigate("/")}>
          <img src={ArrowSvg} alt="Arrow" style={{ verticalAlign: "middle" }} />
          Volver
        </Styled.Anchor>
        <Styled.Title>{product?.title}</Styled.Title>

        <Box
          sx={{
            display: "flex",
            gap: !isDesktop ? 1 : 5,
            flexDirection: !isDesktop ? "column" : "row",
          }}
        >
          <Styled.ProductKey>
            ID: <Styled.ProductValue>{product?._id}</Styled.ProductValue>
          </Styled.ProductKey>

          <Styled.ProductKey>
            Categoría: <Styled.ProductValue>{product?.productType?.name}</Styled.ProductValue>
          </Styled.ProductKey>

          <Styled.ProductKey>
            Descripción: <Styled.ProductValue>{product?.description}</Styled.ProductValue>
          </Styled.ProductKey>
        </Box>
        {/* TABS */}
        <Box
          sx={{
            width: "100%",
            backgroundColor: "#ffffff",
            borderRadius: "12px",
            padding: !isDesktop ? "5px" : "16px",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#127960",
                  borderRadius: "12px",
                  height: 3.5,
                },
                "& .Mui-selected": {
                  color: "#000000!important",
                },
                "& .MuiTab-root": {
                  borderTopLeftRadius: "15px",
                  borderTopRightRadius: "15px",
                },
              }}
            >
              <Tab
                label={
                  <Box style={{ display: "flex" }}>
                    <Typography style={{ fontWeight: 600 }}>Procesos</Typography>
                    <Typography>(Info on-chain)</Typography>
                  </Box>
                }
                {...a11yProps(0)}
                sx={{ textTransform: "inherit" }}
              />
              <Tab
                label={<Typography>Lotes</Typography>}
                {...a11yProps(1)}
                sx={{ textTransform: "inherit" }}
              />
            </Tabs>
            {isDesktop && (
              <Styled.DetailsBtn onClick={() => setOpen(true)}>
                &nbsp;+ Crear Proceso&nbsp;
              </Styled.DetailsBtn>
            )}
          </Box>

          {!isDesktop && (
            <Box sx={{ mt: 3 }}>
              <Styled.DetailsBtn onClick={() => setOpen(true)}>
                &nbsp;+ Crear Proceso&nbsp;
              </Styled.DetailsBtn>
            </Box>
          )}
          <CustomTabPanel value={value} index={0}>
            {!isFetchingProcess ? (
              <TableContainer
                sx={{
                  overflowX: isDesktop ? "unset" : "scroll",
                  backgroundColor: "#ffffff",
                  borderRadius: "12px",
                  "&::-webkit-scrollbar": {
                    height: "12px",
                    width: "12px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#888",
                    borderRadius: "6px",
                  },
                }}
              >
                <Table aria-label="simple table" sx={{ minWidth: "max-content" }}>
                  <TableHead className="hola">
                    <TableRow
                      sx={{
                        "& th": {
                          color: "#000000",
                          backgroundColor: "#F6F7FB",
                          borderBottom: "1px solid #E2E8F0",
                        },
                      }}
                    >
                      <TableCell style={{ fontWeight: 600 }}>Categoría</TableCell>
                      <TableCell style={{ fontWeight: 600 }}>Lote</TableCell>
                      <TableCell style={{ fontWeight: 600 }}>Fecha y Horario</TableCell>
                      <TableCell style={{ fontWeight: 600 }}>Nombre</TableCell>
                      <TableCell style={{ fontWeight: 600 }}>Responsable</TableCell>
                      <TableCell style={{ fontWeight: 600 }}>Link</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {process.map(row => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.signed_data.step.step_category ?? "N/A"}
                        </TableCell>
                        <TableCell>{row.signed_data.batch ?? "N/A"}</TableCell>
                        <TableCell>
                          {row.signed_data.step?.step_date_time
                            ? row.signed_data.step.step_date_time.replace("T", " ")
                            : "N/A"}
                        </TableCell>
                        <TableCell>{row.signed_data.step.step_name}</TableCell>
                        <TableCell>{row.signed_data.step.step_responsible}</TableCell>
                        <TableCell>
                          <a
                            href={row.ipfs_link.replace(
                              "https://ipfs.io/ipfs/",
                              "https://agrojusto-testnet.infura-ipfs.io/ipfs/",
                            )}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Link
                          </a>
                        </TableCell>
                        <TableCell
                          onClick={() =>
                            toast.promise(handleDeleteProcess(row), {
                              loading: "Eliminando proceso...",
                              success: <b>Proceso eliminado!</b>,
                              error: <b>Error al eliminar proceso.</b>,
                            })
                          }
                        >
                          <DeleteForeverOutlinedIcon
                            sx={{
                              color: "#C93448",
                              fontSize: "1.5rem",
                              fontWeight: 600,
                              cursor: "pointer",
                              marginRight: 1,
                              verticalAlign: "bottom",
                            }}
                          />
                          <span
                            style={{
                              color: "#C93448",
                              fontSize: "1rem",
                              cursor: "pointer",
                              fontWeight: 600,
                            }}
                          >
                            Eliminar
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div>Obteniendo informacion...</div>
            )}
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            {product.batchs?.length > 0 && (
              <BatchTable batches={product.batchs} isFetchingProcess={isFetchingProcess} />
            )}
          </CustomTabPanel>
        </Box>

        {/* TABS */}
      </Styled.ViewProductLayout>

      <NewProcessDialog
        open={open}
        setOpen={setOpen}
        stepCategory={stepCategory}
        setStepCategory={setStepCategory}
        stepName={stepName}
        setStepName={setStepName}
        stepBatch={stepBatch}
        setStepBatch={setStepBatch}
        stepDescription={stepDescription}
        setStepDescription={setStepDescription}
        stepResponsible={stepResponsible}
        setStepResponsible={setStepResponsible}
        markerPosition={markerPosition}
        handleMarkerDrag={handleMarkerDrag}
        setImagePreview={setImagePreview}
        imagePreview={imagePreview}
        handleImageChange={handleImageChange}
        batches={product.batchs}
        selectedDateTime={selectedDateTime}
        handleDateTimeChange={handleDateTimeChange}
        handleUploadToIpfs={() =>
          toast.promise(handleUploadToIpfs(), {
            loading: "Guardando información en la blockchain",
            success: <b>Proceso guardado con éxito</b>,
            error: <b>Error al guardar la información</b>,
          })
        }
      />

      <NewBatchDialog
        open={newBatch}
        setOpen={setNewBatch}
        handleCreateNewBatch={handleCreateNewBatch}
        process={process}
        batches={batches}
        newBatchName={newBatchName}
        setNewBatchName={setNewBatchName}
      />
    </>
  );
};

export default ViewProduct;
