import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Modal,
  Box,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  CircularProgress,
  Backdrop,
  Typography,
  FormControlLabel,
  Switch,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import NavBar from "../../components/NavBar";
import * as Styled from "./styles";
import toast from "react-hot-toast";
import { useContract } from "../../hooks/useContract";
import { explorerUrl } from "../../utils/constants";
import { Link } from "react-router-dom";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import InformationMessage from "../../components/InformationMessages/index";

type Batch = {
  _id: string;
  name: string;
  active: boolean;
  isMain: boolean;
  validated: boolean;
  created: string;
  blockchainBatchId: string;
  validationTxHash: string;
};

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "14px",
};

const AllBatchesTable: React.FC = () => {
  const [batches, setBatches] = useState<Batch[]>([]);
  const [filteredBatches, setFilteredBatches] = useState<Batch[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [selectedBatch, setSelectedBatch] = useState<Batch | null>(null);
  const [validationType, setValidationType] = useState<string>("onsite");
  const [validationData, setValidationData] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [url, setUrl] = useState<string>("");

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isValidating, setIsValidating] = useState<boolean>(false);

  const [showOnlyNonValidated, setShowOnlyNonValidated] = useState<boolean>(false);

  const [searchTerm, setSearchTerm] = useState<string>("");

  const isDesktop = useMediaQuery("(min-width:600px)");
  const isMobile = useMediaQuery("(max-width:600px)");

  const db = getFirestore();

  const { handleValidateBatch } = useContract();

  const fetchBatches = async () => {
    setIsFetching(true);
    try {
      const querySnapshot = await getDocs(collection(db, "batches"));
      const fetchedBatches: Batch[] = querySnapshot.docs.map(doc => ({
        _id: doc.id,
        ...doc.data(),
        created: doc.data().created.toDate().toISOString(),
      })) as Batch[];
      //setBatches(fetchedBatches);

      const sortedBatches = fetchedBatches.sort((a, b) => {
        const dateA = new Date(a.created);
        const dateB = new Date(b.created);
        return dateB.getTime() - dateA.getTime();
      });

      console.log("sortedBatches: ", sortedBatches);

      setBatches(sortedBatches);
      setFilteredBatches(sortedBatches);
    } catch (error) {
      console.error("Error fetching batches: ", error);
      alert("Failed to fetch batches");
    } finally {
      setIsFetching(false);
    }
  };

  const toggleValidated = async () => {
    if (!selectedBatch) return;
    setIsValidating(true);
    setIsModalOpen(false);
    const isValidated = await handleValidateBatch({ batchId: selectedBatch.blockchainBatchId });
    console.log("blockchaingValidation", isValidated);
    if (!isValidated?.events.BatchValidated) {
      toast.error(
        "Error al validar lote. Por favor verifica que tengas suficiente RBTC en tu wallet.",
      );
      setIsValidating(false);
      return;
    }

    try {
      const batchDocRef = doc(db, "batches", selectedBatch._id);

      await updateDoc(batchDocRef, {
        validated: true,
        validatedAt: serverTimestamp(),
        validationType,
        validationData,
        company,
        url,
        validationTxHash: isValidated?.transactionHash,
      });

      setBatches(prevBatches =>
        prevBatches.map(batch =>
          batch._id === selectedBatch._id
            ? {
                ...batch,
                validated: true,
                validatedAt: new Date().toISOString(),
              }
            : batch,
        ),
      );

      setIsModalOpen(false);
      setSelectedBatch(null);
      setValidationType("onsite");
      setValidationData("");
      setCompany("");
      setUrl("");
      toast.success("Lote validado con éxito");
    } catch (error) {
      console.error("Error updating validated status: ", error);
      toast.error("Error al validar Lote");
    } finally {
      setIsValidating(false);
    }
  };

  const formatDate = (isoDate: string) => {
    const date = new Date(isoDate);
    return new Intl.DateTimeFormat("es-ES", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZoneName: "short",
    }).format(date);
  };

  useEffect(() => {
    fetchBatches();
  }, []);

  useEffect(() => {
    if (showOnlyNonValidated) {
      setBatches(batches.filter(batch => !batch.validated));
    } else {
      fetchBatches();
    }
  }, [showOnlyNonValidated]);

  // Filter batches when search term changes
  useEffect(() => {
    if (searchTerm === "") {
      setFilteredBatches(batches); // Reset filter if search term is empty
    } else {
      setFilteredBatches(
        batches.filter(batch => batch.name.toLowerCase().includes(searchTerm.toLowerCase())),
      );
    }
  }, [searchTerm, batches]);

  return (
    <>
      <NavBar />
      <Styled.DashboardLayout>
        <InformationMessage isValidating={isValidating} />
        <Styled.Title>Validaciones</Styled.Title>

        <Box display={"flex"} flexDirection={isMobile ? "column" : "row"} gap={2} mb={2} mt={2}>
          {/* Search Input */}
          <TextField
            variant="outlined"
            placeholder="Filtre por nombre..."
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: <SearchIcon style={{ color: "gray", marginRight: "8px" }} />,
            }}
            sx={{
              width: "50%",
              backgroundColor: "white",
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#073026" },
                "&:hover fieldset": { borderColor: "#073026" },
                "&.Mui-focused fieldset": { borderColor: "#073026" },
              },
            }}
          />

          <FormControlLabel
            control={
              <Switch
                checked={showOnlyNonValidated}
                onChange={e => setShowOnlyNonValidated(e.target.checked)}
                color="primary"
                sx={{
                  "& .MuiSwitch-switchBase.Mui-checked": {
                    color: "#127960", // Color of the switch when checked
                  },
                  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                    backgroundColor: "#127960", // Background color of the track when checked
                  },
                }}
              />
            }
            label="Mostrar solo a validar"
          />
          <IconButton
            onClick={fetchBatches}
            sx={{
              color: "#127960",
              display: "flex",
              alignItems: "center",
              gap: 1,
              justifyContent: isMobile ? "start" : "center",
            }}
          >
            <RefreshIcon />
            <Typography sx={{ fontWeight: 600, fontSize: "1rem" }}>Actualizar</Typography>
          </IconButton>
        </Box>

        {!isFetching ? (
          <>
            <Box
              sx={{
                width: "100%",
                backgroundColor: "#ffffff",
                borderRadius: "12px",
                padding: !isDesktop ? "5px" : "16px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <TableContainer
                sx={{
                  overflowX: isDesktop ? "unset" : "scroll",
                  backgroundColor: "#ffffff",
                  borderRadius: "12px",
                  "&::-webkit-scrollbar": {
                    height: "12px",
                    width: "12px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#888",
                    borderRadius: "6px",
                  },
                }}
              >
                <Table sx={{ minWidth: "max-content" }}>
                  <TableHead>
                    <TableRow
                      sx={{
                        "& th": {
                          color: "#000000",
                          backgroundColor: "#F6F7FB",
                          borderBottom: "1px solid #E2E8F0",
                        },
                      }}
                    >
                      <TableCell style={{ fontWeight: 600 }}>Nombre</TableCell>
                      <TableCell style={{ fontWeight: 600 }}>Estado</TableCell>
                      <TableCell style={{ fontWeight: 600 }}>Información</TableCell>
                      <TableCell style={{ fontWeight: 600 }}>Validado</TableCell>
                      <TableCell style={{ fontWeight: 600 }}>Fecha</TableCell>
                      <TableCell style={{ fontWeight: 600 }}>Tx</TableCell>
                      <TableCell style={{ fontWeight: 600, width: "15%" }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredBatches.map(batch => (
                      <TableRow key={batch._id}>
                        <TableCell>{batch.name}</TableCell>
                        <TableCell>{batch.active ? "Activo" : "Inactivo"}</TableCell>
                        <TableCell>{batch.isMain ? "Actual" : "N/A"}</TableCell>
                        <TableCell>{batch.validated ? "Si" : "No"}</TableCell>
                        <TableCell>{formatDate(batch.created)}</TableCell>

                        <TableCell>
                          {batch.validationTxHash && (
                            <Link
                              to={`${explorerUrl}/tx/${batch.validationTxHash}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Ver Tx
                            </Link>
                          )}
                        </TableCell>
                        <TableCell>
                          {batch.validated ? (
                            <Typography
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                color: "#127960",
                                fontWeight: 600,
                                justifyContent: "center",
                              }}
                            >
                              <CheckCircleOutlinedIcon sx={{ marginRight: 1 }} />
                              Validado
                            </Typography>
                          ) : (
                            <Styled.DetailsBtn
                              variant="contained"
                              disabled={batch.validated}
                              onClick={() => {
                                setSelectedBatch(batch);
                                setIsModalOpen(true);
                              }}
                            >
                              Validar
                            </Styled.DetailsBtn>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </>
        ) : (
          <div>Cargando lotes para validar...</div>
        )}
      </Styled.DashboardLayout>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box sx={modalStyle}>
          <Typography sx={{ color: "#127960", fontWeight: 500, fontSize: "0.75rem", mb: 1 }}>
            Validar Lote
          </Typography>
          <Typography sx={{ color: "#101828", fontWeight: 600, fontSize: "1.125rem", mb: 4 }}>
            {selectedBatch?.name}
          </Typography>

          <Tooltip title="Seleccione el tipo de validación que se realizará" arrow>
            <FormControl
              fullWidth
              variant="outlined"
              sx={{
                //marginBottom: 1,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "#073026" },
                  "&:hover fieldset": { borderColor: "#073026" },
                  "&.Mui-focused fieldset": { borderColor: "#073026" },
                },
              }}
            >
              <InputLabel
                required
                id="demo-simple-select-standard-label"
                sx={{
                  color: "#073026",
                  "&.Mui-focused": { color: "#073026" },
                }}
              >
                Tipo de Validación
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                variant="outlined"
                fullWidth
                label="Tipo deValidación"
                value={validationType}
                onChange={e => setValidationType(e.target.value)}
                sx={{
                  borderColor: "#073026",
                  "& fieldset": { borderColor: "#073026" },
                }}
              >
                <MenuItem value="onsite">Presencial</MenuItem>
                <MenuItem value="remote">Remoto</MenuItem>
              </Select>
            </FormControl>
          </Tooltip>

          <Tooltip title="Persona u organización responsable de validar este proceso" arrow>
            <TextField
              required
              autoFocus
              margin="dense"
              id="company"
              label="Responsable de Validación"
              type="text"
              fullWidth
              variant="outlined"
              value={company}
              onChange={e => setCompany(e.target.value)}
              sx={{
                "& label": { color: "#073026" },
                "& label.Mui-focused": { color: "#073026" },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "#073026" },
                  "&:hover fieldset": { borderColor: "#073026" },
                  "&.Mui-focused fieldset": { borderColor: "#073026" },
                },
              }}
            />
          </Tooltip>

          <Tooltip
            title="Inserte aquí el vínculo o URL relevante para detallar más información"
            arrow
          >
            <TextField
              required
              autoFocus
              margin="dense"
              id="url"
              label="Enlace de interés"
              type="text"
              fullWidth
              variant="outlined"
              value={url}
              onChange={e => setUrl(e.target.value)}
              sx={{
                "& label": { color: "#073026" },
                "& label.Mui-focused": { color: "#073026" },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "#073026" },
                  "&:hover fieldset": { borderColor: "#073026" },
                  "&.Mui-focused fieldset": { borderColor: "#073026" },
                },
              }}
            />
          </Tooltip>

          <Tooltip title="Observaciones adicionales o detalles importantes" arrow>
            <TextField
              label="Comentarios"
              margin="dense"
              multiline
              rows={4}
              fullWidth
              value={validationData}
              onChange={e => setValidationData(e.target.value)}
              variant="outlined"
              sx={{
                "& label": { color: "#073026" },
                "& label.Mui-focused": { color: "#073026" },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "#073026" },
                  "&:hover fieldset": { borderColor: "#073026" },
                  "&.Mui-focused fieldset": { borderColor: "#073026" },
                },
              }}
            />
          </Tooltip>

          <Box sx={{ display: "flex", flexDirection: "row", pt: 2, mt: 2 }}>
            <Styled.Cancel color="inherit" onClick={() => setIsModalOpen(false)} sx={{ mr: 1 }}>
              Cancelar
            </Styled.Cancel>

            <Styled.Confirm onClick={toggleValidated}>Confirmar Validación</Styled.Confirm>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AllBatchesTable;
