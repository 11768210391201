import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../providers/auth";
import useDataStore from "../../store";
import NavBar from "../../components/NavBar";
import * as Styled from "./styles";
import ArrowSvg from "../../assets/Arrow.svg";
import { ethers } from "ethers";
import { Backdrop, CircularProgress, Typography } from "@mui/material";
import { controllerContractAddress, explorerUrl, isMainnet } from "../../utils/constants";
import { Box, Paper, Divider, IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import toast from "react-hot-toast";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const Profile = () => {
  const { handleLogout, account, web3 } = useAuth();
  const { userData, userRole } = useDataStore();
  let navigate = useNavigate();

  const [balance, setBalance] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);

  const logout = async () => {
    setIsLoggingOut(true);
    await handleLogout();
    setIsLoggingOut(false);
  };

  useEffect(() => {
    fetchUserBalance();
  }, [account, web3]);

  if (!userData) {
    window.location.href = "https://my.agrojusto.com.ar/";
  }

  const fetchUserBalance = async () => {
    if (!web3) return;
    if (!account) return;
    try {
      setLoading(true);
      const balance = await web3.eth.getBalance(account);
      setBalance(ethers.formatEther(balance));
    } catch (error) {
      console.error("Error fetching user balance:", error);
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Dirección Copiada");
      })
      .catch(err => {
        console.error("Error copying to clipboard:", err);
        toast.error("Error al copiar");
      });
  };

  return (
    <>
      <NavBar />
      <Styled.DashboardLayout>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            border: "1px solid #0029FF",
            borderRadius: "8px",
            padding: "14px 14px",
            mb: 2,
          }}
        >
          <WarningAmberIcon sx={{ color: "#F4B400", marginRight: "8px" }} />
          <Box sx={{ flexGrow: 1, color: "black" }} style={{ fontWeight: "500" }}>
            Para poder publicar o validar procesos es muy importante que tengas saldo disponible en
            tu billetera
          </Box>
          <Link
            to="#"
            target="_blank"
            style={{
              marginLeft: "auto",
              color: "#0029FF",
              textDecoration: "none",
            }}
          >
            Agregar saldo ↗
          </Link>
        </Box>

        <Styled.Anchor onClick={() => navigate("/")}>
          <img src={ArrowSvg} alt="Arrow" style={{ verticalAlign: "middle" }} />
          Volver
        </Styled.Anchor>

        <Styled.Title>Mis datos</Styled.Title>

        <Box sx={{ display: "flex", gap: 3 }}>
          <Styled.ProductKey>
            Email: <Styled.ProductValue>{userData?.user?.email}</Styled.ProductValue>
          </Styled.ProductKey>

          <Styled.ProductKey>
            Rol:{" "}
            <Styled.ProductValue>
              {userRole?.isAdmin && "Administrador /"}
              {userRole?.isValidator && " Validador"}
              {!userRole?.isValidator && !userRole?.isAdmin && "Productor"}
            </Styled.ProductValue>
          </Styled.ProductKey>
        </Box>

        <Box sx={{ display: "flex" }}>
          <Styled.ProductKey>
            Entorno: {isMainnet ? "Mainnet" : "Testnet"}
            &nbsp;&gt;&nbsp;
            <Link
              to={`${explorerUrl}/address/${controllerContractAddress as string}`}
              target="_blank"
              style={{ fontSize: "0.65rem" }}
            >
              {controllerContractAddress as string}
            </Link>
          </Styled.ProductKey>
        </Box>

        <Box display="flex" flexDirection={{ xs: "column", md: "row" }} gap={2} mb={2}>
          <Box
            flex={1}
            p={2}
            borderRadius={2}
            border="1px solid #ccc"
            sx={{ backgroundColor: "white" }}
          >
            <Typography sx={{ fontWeight: 500, fontSize: "1rem" }}>Dirección Blockchain</Typography>
            <Divider sx={{ my: 1, mb: 4 }} />
            <Box
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
              alignItems="center"
              p={1}
              borderRadius={1}
              border="1px solid #073026"
            >
              <Typography
                sx={{ flexGrow: 1, fontWeight: 500, fontSize: "0.75rem", color: "#073026" }}
              >
                {account}
              </Typography>
              <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
              <IconButton
                size="small"
                onClick={() => copyToClipboard(account)}
                style={{ color: "#344054" }}
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
              <Typography
                variant="body2"
                sx={{ ml: 1, cursor: "pointer", color: "#344054" }}
                onClick={() => copyToClipboard(account)}
              >
                Copiar
              </Typography>
            </Box>
          </Box>

          <Box
            flex={1}
            p={2}
            borderRadius={2}
            border="1px solid #ccc"
            sx={{ backgroundColor: "white" }}
          >
            <Typography sx={{ fontWeight: 500, fontSize: "1rem" }}>Balance</Typography>
            <Divider sx={{ my: 1, mb: 4 }} />
            {loading ? (
              <Typography variant="body2">Cargando...</Typography>
            ) : (
              <Typography sx={{ fontWeight: 600, fontSize: "1.71rem", color: "#000000" }}>
                {balance} <span style={{ fontSize: "0.875rem", color: "#667085" }}>tRBTC</span>
              </Typography>
            )}
            <Typography sx={{ fontSize: "0.875rem", color: "#667085" }}>
              Aprende como agregar{" "}
              <Link to="" style={{ color: "#0029FF", textDecoration: "none" }}>
                saldo a tu billetera ↗
              </Link>
            </Typography>
          </Box>
        </Box>

        <Styled.LogoutBtn onClick={() => logout()}>Cerrar sesión</Styled.LogoutBtn>
        <Box sx={{ mt: 5, mb: 3 }}></Box>
      </Styled.DashboardLayout>
      <Backdrop
        open={isLoggingOut}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
          color: "white",
        }}
      >
        <CircularProgress color="inherit" />
        <Typography variant="h6" color={"white"}>
          Cerrando sesión...
        </Typography>
      </Backdrop>
    </>
  );
};

export default Profile;
