import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

interface NavButtonProps {
  isActive: boolean;
}

export const NavButton = styled(Button)<NavButtonProps>(({ isActive }) => ({
  fontFamily: "Poppins, sans-serif",
  fontSize: "1rem",
  fontWeight: isActive ? 700 : 500,
  color: "black",
  textTransform: "capitalize",
  position: "relative",
  backgroundColor: "transparent",
  "&:after": isActive
    ? {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "4px",
        bottom: "-16px",
        left: 0,
        borderTop: "4px solid #127960",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
      }
    : {},
}));
